<template>
  <div class="page-content">
    <div class="row" style="height: auto !important">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="card-body" v-loading="loading.list">
            <div class="row position-relative">
              <div class="col-lg-4 col-md-12 col-sm-6 col-xs-12 overflow-auto" style="max-height:580px">
                <div class="row mb-4 ml-1 mr-4 d-flex justify-content-between">
                  <el-button class="px-2" size="small" type="primary" plain @click="publish()">Publish Content</el-button>
                  <div class="border border-dark rounded p-2 threads">
                    <p>Total : {{ count_all }}</p>
                  </div>
                </div>
                <form @submit.prevent="getThreads" class="form-group pr-4">
                  <input v-model="search.keyword" type="text" class="form-control border rounded-sm" placeholder="Search here...">
                </form>
                <div class="row">
                  <div class="col-sm-12">
                    <ul class="list-unstyled chat-list pr-4">
                      <li class="chat-item py-1 cursor-pointer" v-for="(dt, i) in searchThreads()" :key="i" @click="showDetail(dt)">
                        <div class="border border-dark rounded p-2 threads">
                          <p>{{ ucwords(dt.thread_name) }}</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-8 pl-2 overflow-auto" style="max-height:580px" v-loading="loading.detail">
                <div class="border border-left rounded p-4">
                  <div v-if="details?.id">
                    <div class="mb-2">
                      <h4>{{ ucwords(details.thread_name) }}</h4>
                    </div>
                    <ul class="list-unstyled">
                      <li class="chat-item py-2 cursor-pointer" v-for="(v, i) in details.thread_details" :key="i" @click="editThread(v)">
                        <div class="border border-dark rounded p-2 threads">
                          <p>{{ v.content }}</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="align-items-center text-center" v-else>
                    <el-empty description="No threads"></el-empty>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="Edit Threads"
      :visible.sync="dialogVisible"
      width="50%"
    >
      <el-form ref="form" label-position="left" label-width="70px">
        <el-form-item label="Content">
          <el-input type="textarea" rows="5" v-model="form.content"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="save()" :loading="loading.submit">Save</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import popupErrorMessages from '@/library/popup-error-messages';
import threadsAPI from '../../api/ai_threads';

export default {
  name: 'AIThreads',
  metaInfo: {
    title: 'AI Threads',
  },
  computed: {
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
  },
  data() {
    return {
      dialogVisible: false,
      loading: {
        list: '',
        detail: '',
        update: '',
      },
      search: {
        keyword: '',
      },
      form: {
        id: '',
        content: '',
        name: '',
      },
      count_all: 0,
      details: {},
      list_details: [],
      threads: [],
    };
  },
  mounted() {
    this.getThreads();
  },
  methods: {
    space(str) {
      return str.replace(/_/gi, ' ');
    },
    ucwords(str) {
      const words = this.space(str).split(' ');
      words.forEach((v, i) => {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      });
      return words.join(' ');
    },
    handleClose(done) {
      this.$confirm('Are you sure to close this dialog?')
        .then(() => {
          done();
        })
        .catch(() => {});
    },
    searchThreads() {
      const result = this.threads;
      // if (this.search.keyword) {
      //   result = this.threads.filter((v) => v.thread_name.includes(this.search.keyword));
      // }
      return result;
    },
    editThread(dt) {
      this.form.content = dt.content;
      this.form.id = dt.id;
      this.dialogVisible = true;
    },
    async save() {
      this.loading.submit = true;
      await threadsAPI.update({
        thread_id: this.form.id,
        workspace_id: this.activeWorkspace._id,
        data: {
          content: this.form.content,
        },
      }).then(async (response) => {
        await popupErrorMessages(response);
        this.$message({
          type: 'success',
          message: this.$t('general.success'),
        });
        this.showDetail(this.details);
      }).catch(() => {
        this.loading.submit = false;
      });
      this.loading.submit = false;
      this.dialogVisible = false;
    },
    async getThreads() {
      this.loading.list = true;
      this.details = {};
      if (this.search.keyword === '') {
        await threadsAPI.getList({
          workspace_id: this.activeWorkspace._id,
        }).then(async (response) => {
          this.threads = response.data.data;
          this.count_all = this.threads.length;
          this.loading.list = false;
        }).catch(async (err) => {
          await popupErrorMessages(err);
          this.loading.list = false;
        });
      } else {
        await threadsAPI.getSearch({
          workspace_id: this.activeWorkspace._id,
          keyword: this.search.keyword,
        }).then(async (response) => {
          this.threads = response.data.data;
          this.count_all = this.threads.length;
          this.loading.list = false;
        }).catch(async (err) => {
          await popupErrorMessages(err);
          this.loading.list = false;
        });
      }
      this.loading.list = false;
    },
    publish() {
      this.$confirm(this.$t('ai_threads.confirm_publish'), this.$t('general.confirmation'), {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        center: true,
        /* eslint-disable no-param-reassign */
        beforeClose: async (action, instance, cb) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;

            const response = await threadsAPI.setSync({
              workspace_id: this.activeWorkspace._id,
            });
            instance.confirmButtonLoading = false;
            await popupErrorMessages(response);

            this.$message({
              message: this.$t('ai_threads.success_publish'),
              type: 'success',
            });
            this.search.keyword = '';
            this.getThreads();
            cb();
            return;
          }
          instance.confirmButtonLoading = false;
          cb();
        },
      }).catch(() => {});
    },
    async showDetail(dt) {
      this.loading.detail = true;
      await threadsAPI.getDetail({
        thread_id: dt.id,
        workspace_id: this.activeWorkspace._id,
      }).then(async (response) => {
        await popupErrorMessages(response);
        this.details = response.data.data;
        this.loading.detail = false;
      }).catch(() => {
        this.loading.detail = false;
      });
      this.loading.detail = false;
    },
  },
};
</script>
<style>
.threads:hover {
  background-color: rgb(227, 227, 227);
}
</style>
