<template>
  <div class="page-content">
    <div class="row report-wrapper">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row position-relative mb-3">
              <div class="col-md-12 report-header">
                <h5>Analytics overview in</h5>
                <el-select size="small" @change="filterDateTypeChanged" v-model="filter.date_type">
                  <el-option v-for="(item, index) in filter_by_list" :value="item.value" :label="item.label" :key="index">{{ item.label }}</el-option>
                </el-select>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-md-4 text-center">
                <div class="card">
                  <div class="card-body" v-loading="loading.all">
                    <h6 class="mb-3">Messages</h6>
                    <span class="big-icon"><i class="mdi mdi-email"></i></span>
                    <p class="mt-3"><i class="mdi mdi-call-received"></i> {{ message_count.inbound }}
                    <span class="spacer"></span><i class="mdi mdi-call-made"></i> {{ message_count.outbond }}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-4 text-center">
                <div class="card">
                  <div class="card-body" v-loading="loading.all">
                    <h6 class="mb-3">Chats</h6>
                    <span class="big-icon"><i class="mdi mdi-bookmark-check"></i></span>
                    <p class="mt-3"><i class="mdi mdi-bookmark-check"></i> {{ ticket_count.all }}<span class="spacer"></span></p>
                  </div>
                </div>
              </div>
              <div class="col-md-4 text-center">
                <div class="card">
                  <div class="card-body" v-loading="loading.all">
                    <h6 class="mb-3">Contacts</h6>
                    <span class="big-icon"><i class="mdi mdi-contacts"></i></span>
                    <p class="mt-3"><i class="mdi mdi-contacts"></i> {{ contact_count.all }} <span
                        class="spacer"></span></p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-12">
                <div class="card">
                  <div class="card-body" v-loading="loading.broadcast_analytic_chart" style="min-height: 380px;">
                    <div class="card-head mb-3">
                      <h5 class="float-left">Broadcast Analytics</h5>
                    </div>
                    <apexchart ref="broadcast_analytic_chart" :options="broadcast_analytic_options" :series="broadcast_analytic_options.series" height="250px"></apexchart>
                    <!-- <apexchart v-if="!isEmpty(group_chart_options.series.filter(v => v > 0))" height="300" id="apexpie" :options="group_chart_options" :series="group_chart_options.series"></apexchart>
                    <el-empty v-else description="No data"></el-empty> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="row chart-by mb-2">
              <div class="col-md-3">
                <div class="card">
                  <div class="card-body" v-loading="loading.group_agent" style="min-height: 380px;">
                    <div class="card-head mb-3">
                      <h5 class="float-left">By group</h5>
                      <!-- <a href="#" class="float-right">Details</a> -->
                    </div>
                    <apexchart v-if="!isEmpty(group_chart_options.series.filter(v => v > 0))" height="300" id="apexpie" :options="group_chart_options" :series="group_chart_options.series"></apexchart>
                    <el-empty v-else description="No data"></el-empty>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card">
                  <div class="card-body" v-loading="loading.agent" style="min-height: 380px;">
                    <div class="card-head mb-3">
                      <h5 class="float-left">By agent</h5>
                      <!-- <a href="#" class="float-right">More Details</a> -->
                    </div>
                    <apexchart v-if="!isEmpty(agent_chart_options.series.filter(v => v > 0))" height="300" :options="agent_chart_options" :series="agent_chart_options.series"></apexchart>
                    <el-empty v-else description="No data"></el-empty>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card">
                  <div class="card-body" v-loading="loading.label" style="min-height: 380px;">
                    <div class="card-head mb-3">
                      <h5 class="float-left">By label</h5>
                      <!-- <a href="#" class="float-right">More Details</a> -->
                    </div>
                    <apexchart v-if="!isEmpty(label_chart_options.series.filter(v => v > 0))" height="300" :options="label_chart_options" :series="label_chart_options.series"></apexchart>
                    <el-empty v-else description="No data"></el-empty>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card">
                  <div class="card-body" v-loading="loading.channel" style="min-height: 380px;">
                    <div class="card-head mb-3">
                      <h5 class="float-left">By channel</h5>
                      <!-- <a href="#" class="float-right">More Details</a> -->
                    </div>
                    <apexchart v-if="!isEmpty(channel_chart_options.series.filter(v => v > 0))" height="300" :options="channel_chart_options" :series="channel_chart_options.series"></apexchart>
                    <el-empty v-else description="No data"></el-empty>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="card w-100">
                  <div class="card-body" v-loading="loading.agent_ticket" style="min-height: 380px;">
                    <div class="card-head mb-3">
                      <h5 class="float-left">Agent Ticket Summary</h5>
                      <!-- <a href="#" class="float-right">Details</a> -->
                    </div>
                    <div class="table-responsive">
                      <table class="table table-hover">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <!-- <th>Email</th> -->
                            <!-- <th>Last Login Date</th> -->
                            <th>Availibility Status</th>
                            <th>Last Online Date</th>
                            <th>Last Offline Date</th>
                            <th>Open Ticket</th>
                            <th>Handling Ticket</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="agent in agentList" :key="agent.id" style="cursor: pointer">
                            <td>{{ agent.name }}</td>
                            <!-- <td>{{ agent.last_login_at_str }}</td> -->
                            <td>
                              <el-badge is-dot :type="agent.is_available ? 'success' : 'danger'"></el-badge>
                              {{ agent.is_available ? 'Online' : 'Offline' }}
                            </td>
                            <td>{{ agent.last_online_at_str }}</td>
                            <td>{{ agent.last_offline_at_str }}</td>
                            <td>{{ agent.total_open }}</td>
                            <td>{{ agent.total_handling }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <el-empty v-if="totalRow == 0" description="No data"></el-empty>
                    <b-pagination v-if="totalRow > search_agent.per_page" v-model="currentPage" :total-rows="totalRow" :per-page="search_agent.per_page"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  orderBy, isEmpty, uniqBy, upperFirst,
} from 'lodash';
import moment from 'moment';
import analyticApi from '../../api/analytics';
import popupErrorMessages from '../../library/popup-error-messages';
import agents from '../../api/agents';

export default {
  beforeCreate() {
    this.isEmpty = isEmpty;
  },
  name: 'Dashboard',
  metaInfo: {
    title: 'Dashboard',
  },
  data() {
    return {
      agentList: [],
      filter_by_list: [
        {
          label: 'This month',
          value: 'this_month',
        },
        {
          label: 'Last month',
          value: 'last_month',
        },
        {
          label: 'Today',
          value: 'today',
        },
        {
          label: 'Yesterday',
          value: 'yesterday',
        },
        {
          label: 'This week',
          value: 'this_week',
        },
        {
          label: 'Last week',
          value: 'last_week',
        },
      ],
      filter: {
        date_type: 'this_month',
      },
      loading: {
        all: true,
        label: true,
        agent: true,
        group_agent: true,
        channel: true,
        agent_ticket: true,
        broadcast_analytic_chart: true,
      },
      loader: null,
      loaderStack: 0,
      message_count: {
        inbound: 0,
        outbond: 0,
      },
      ticket_count: {
        all: 0,
      },
      contact_count: 0,
      label_chart_options: {
        chart: {
          height: 400,
          type: 'donut',
        },
        stroke: {
          colors: ['rgba(0,0,0,0)'],
        },
        labels: ['dummy1', 'dummy2', 'dummy3', 'dummy4'],
        colors: ['#f77eb9', '#7ee5e5', '#4d8af0', '#fbbc06'],
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
        },
        dataLabels: {
          enabled: true,
        },
        series: [],
        tooltip: {
          fixed: {
            enabled: true,
          },
        },
        showTooltips: false,
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
              },
              total: {
                show: false,
                showAlways: false,
                label: 'Total',
                fontSize: '22px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 600,
                color: '#373d3f',
                formatter: (w) => w.globals.seriesTotals.reduce((a, b) => a + b, 0),
              },
            },
          },
        },
      },
      agent_chart_options: {
        chart: {
          height: 400,
          type: 'pie',
        },
        stroke: {
          colors: ['rgba(0,0,0,0)'],
        },
        labels: [],
        colors: ['#7cb6ef', '#81d2d3', '#4d8af0', '#fbbc06', '#F47983'],
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
        },
        dataLabels: {
          enabled: true,
        },
        series: [],
        tooltip: {
          fixed: {
            enabled: true,
          },
        },
      },
      group_chart_options: {
        chart: {
          height: 400,
          type: 'donut',
        },
        stroke: {
          colors: ['rgba(0,0,0,0)'],
        },
        labels: [],
        colors: ['#81d2d3', '#7eb512', '#4d8af0', '#fbbc06', '#F47983'],
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
        },
        dataLabels: {
          enabled: true,
        },
        tooltip: {
          fixed: {
            enabled: true,
          },
        },
        series: [],
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
              },
              total: {
                show: false,
                showAlways: false,
                label: 'Total',
                fontSize: '22px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 600,
                color: '#373d3f',
                formatter: (w) => w.globals.seriesTotals.reduce((a, b) => a + b, 0),
              },
            },
          },
        },
      },
      channel_chart_options: {
        chart: {
          height: 400,
          type: 'pie',
        },
        stroke: {
          colors: ['rgba(0,0,0,0)'],
        },
        tooltip: {
          fixed: {
            enabled: true,
          },
        },
        labels: [],
        colors: ['#f77eb9', '#7ee5e5', '#4d8af0', '#fbbc06', '#F47983'],
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
        },
        dataLabels: {
          enabled: true,
        },
        series: [],
      },
      broadcast_analytic_options: {
        series: [],
        chart: {
          height: 250,
          type: 'area',
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: [1, 1, 1, 1],
          curve: 'smooth',
        },
        xaxis: {},
        yaxis: {
          title: {
            text: 'Total Messages',
          },
        },
        fill: {
          opacity: 5,
        },
        tooltip: {
          shared: true,
          y: {
            formatter: (val) => val.toLocaleString('id-ID'),
          },
        },
        colors: [],
      },
      search_agent: {
        page: 1,
        per_page: 10,
      },
      totalRow: 0,
    };
  },
  async mounted() {
    this.showLoader();
    await this.getAnalytic();
    this.getAgentList();
    this.getChartBroadcast();
    this.hideLoader();
  },
  computed: {
    workspaces() {
      return this.$store.state.workspace.workspaces.map((workspace) => ({
        text: workspace.name,
        value: workspace._id,
      }));
    },
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
    currentPage: {
      get() {
        return this.search_agent.page;
      },
      set(val) {
        this.search_agent.page = val;
        this.getAgentList();
      },
    },
  },
  methods: {
    async getChartBroadcast() {
      let start_date = moment().format();
      let end_date = moment().format();
      if (this.filter.date_type === 'this_month') {
        start_date = moment().startOf('month').format();
        end_date = moment().endOf('month').format();
      }
      if (this.filter.date_type === 'last_month') {
        start_date = moment().subtract(1, 'month').startOf('month').format();
        end_date = moment().subtract(1, 'month').endOf('month').format();
      }
      if (this.filter.date_type === 'last_week') {
        start_date = moment().subtract(1, 'week').startOf('week').format();
        end_date = moment().subtract(1, 'week').endOf('week').format();
      }
      if (this.filter.date_type === 'this_week') {
        start_date = moment().startOf('week').format();
        end_date = moment().endOf('week').format();
      }
      if (this.filter.date_type === 'yesterday') {
        start_date = moment().subtract(1, 'day').format();
        end_date = moment().subtract(1, 'day').format();
      }
      if (this.filter.date_type === 'today') {
        start_date = moment().startOf('day').format();
        end_date = moment().endOf('day').format();
      }
      this.broadcast_analytic_options.xaxis = {
        type: 'datetime',
        min: new Date(moment(start_date).format('YYYY-MM-DD')).getTime(),
        max: new Date(moment(end_date).format('YYYY-MM-DD')).getTime(),
      };
      if (moment(end_date).isAfter(moment(), 'day')) {
        this.broadcast_analytic_options.xaxis.max = new Date(moment().format('YYYY-MM-DD')).getTime();
      }
      this.loading.broadcast_analytic_chart = true;
      const response = await analyticApi.broadcastChart({
        workspace_id: this.activeWorkspace._id,
        start_date,
        end_date,
      });
      this.loading.broadcast_analytic_chart = false;
      this.broadcast_analytic_options.series = [];
      const status_color = {
        delivered: '#B7E0FF',
        failed: '#CC2B52',
        read: '#15B392',
        sent: '#F3C623',
      };
      if (!response.error) {
        this.loading.broadcast_analytic_chart = true;
        if (response.data.rows && Object.keys(response.data.rows).length) {
          Object.keys(response.data.rows).forEach((key) => {
            const o = {
              name: upperFirst(key),
              data: [],
            };
            response.data.rows[key].forEach((v) => {
              /* eslint-disable radix */
              o.data.push([(new Date(moment(v.date).format('YYYY-MM-DD')).getTime()), v.total]);
            });
            this.broadcast_analytic_options.colors.push(status_color[key]);
            this.broadcast_analytic_options.series.push(o);
          });
        }
        if (this.$refs.broadcast_analytic_chart) await this.$refs.broadcast_analytic_chart.refresh();
        this.loading.broadcast_analytic_chart = false;
      }
      console.log(this.broadcast_analytic_options);
    },
    async getAgentList() {
      this.loading.agent_ticket = true;
      await agents.getListSummaryTicket(this.activeWorkspace._id, {
        page: this.search_agent.page,
        per_page: this.search_agent.per_page,
      }).then(async (res) => {
        await popupErrorMessages(res);
        this.totalRow = res.data.count;
        const mapped_data = res.data.rows.map((agent) => {
          const data = agent;
          data.last_online_at_str = agent.last_online_at ? moment(agent.last_online_at).format('DD MMM YYYY, HH:mm') : '-';
          data.last_offline_at_str = agent.last_offline_at ? moment(agent.last_offline_at).format('DD MMM YYYY, HH:mm') : '-';
          return data;
        });
        this.agentList = mapped_data;
      }).catch(() => {});
      this.loading.agent_ticket = false;
    },
    async getAnalytic() {
      this.loading.all = true;
      this.loading.label = true;
      this.loading.agent = true;
      this.loading.group_agent = true;
      this.loading.channel = true;

      const response = await analyticApi.dashboardAnalytic({
        workspace_id: this.activeWorkspace._id,
        date_type: this.filter.date_type,
      }).catch(() => {});
      this.loading.all = false;
      await popupErrorMessages(response);

      this.message_count = {
        inbound: response.data.inbound_messages[0].count,
        outbond: response.data.outbond_messages[0].count,
      };
      this.ticket_count = {
        all: response.data.tickets[0].count,
      };
      this.contact_count = {
        all: response.data.contacts[0].count,
      };
      this.renderLabel(orderBy(response.data.labels, 'count', 'desc').splice(0, 5));
      this.renderAgent(orderBy(response.data.ticket_agent, 'count', 'desc').splice(0, 5));
      this.renderGroup(orderBy(response.data.ticket_group, 'count', 'desc').splice(0, 5));
      this.renderChannel(response.data.ticket_channel);
    },
    renderLabel(data = []) {
      this.label_chart_options = {
        ...this.label_chart_options,
        colors: data.map((v) => v.color),
        series: data.map((v) => v.count),
        labels: data.map((v) => v.title),
      };
      this.loading.label = false;
    },
    renderAgent(data = []) {
      this.agent_chart_options = {
        ...this.agent_chart_options,
        series: data.map((v) => v.count),
        labels: data.map((v) => v.agent_name),
      };
      this.loading.agent = false;
    },
    renderGroup(data = []) {
      this.group_chart_options = {
        ...this.group_chart_options,
        series: data.map((v) => v.count),
        labels: data.map((v) => v.group_name),
      };
      this.loading.group_agent = false;
    },
    /* eslint-disable no-param-reassign */
    renderChannel(data = []) {
      const color = {
        whatsapp: '#26A65B',
        telegram: 'rgb(100, 187, 228)',
        sms: '#81d2d3',
      };
      data = uniqBy(data, 'channel_name');
      this.channel_chart_options = {
        ...this.channel_chart_options,
        series: data.map((v) => v.count),
        labels: data.map((v) => v.channel_name),
        colors: data.map((v) => color[v.channel_name.toLowerCase()]),
      };
      this.loading.channel = false;
    },
    showLoader() {
      if (this.loaderStack === 0) {
        this.loader = this.$loading.show();
      }
      this.loaderStack += 1;
    },
    hideLoader() {
      this.loaderStack -= 1;
      if (this.loaderStack === 0) {
        this.loader.hide();
      }
    },
    filterDateTypeChanged() {
      this.getAnalytic();
      this.getChartBroadcast();
    },
  },
};
</script>
<style>
  /*--------------*/
  span.big-icon {
    font-size: 45px;
    line-height: 100%;
    color: #128c7f;
  }

  .report-wrapper .spacer {
    display: inline-block;
    width: 10px;
    height: 20px;
  }

  .report-wrapper .card-head {
    width: 100%;
    display: block;
    height: 20px;
  }

  .report-wrapper a {
    color: #128c7f;
  }

  .chart-by #apexMixed-1 .apexcharts-legend,
  .chart-by #apexMixed-2 .apexcharts-legend,
  .chart-by #apexMixed-3 .apexcharts-legend {
    bottom: -20px !important;
  }

  .report-header h5 {
    float: left;
    margin-right: 30px;
  }

  .report-header select {
    max-width: 300px;
    float: left;
    margin-top: -10px;
  }

</style>
