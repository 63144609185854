<template>
  <b-modal v-model="showModal" title="Send Message" hide-footer size="xl" @hide="onClose">
    <b-row class="mt-2 mb-4">
      <b-col sm="9">
        <b-card class="">
          <b-card-title class="text-capitalize">Template Name</b-card-title>
          <b-card-sub-title class="mb-4">Select template you want to use.</b-card-sub-title>
          <el-select placeholder="Select template" v-model="form.template_id" @change="handlerChangeTemplate" filterable class="w-100">
            <el-option :value="item.id" v-for="(item, i) in templateList" :key="i" :label="item.template_name_str"/>
          </el-select>
          <!-- Template Header Media Variable -->
          <div v-if="selected_template.component_header && selected_template.component_header.format.toLowerCase() !== 'text'">
            <hr/>
            <b-card-title class="text-capitalize">Template Variables (Header)</b-card-title>
            <b-card-sub-title class="mb-4">Input file media to send.</b-card-sub-title>
            <b-row v-if="selected_template.component_header.format.toLowerCase() !== 'text'">
              <b-col md="3">
                <el-select @change="handlerHeaderMediaChange" v-model="form.header_params.input_type">
                  <el-option value="file" label="File input"/>
                  <el-option v-if="form.recipient_type === 'csv'" value="csv" label="CSV Column"/>
                  <el-option value="url" label="URL input"/>
                </el-select>
              </b-col>
              <b-col md="9" v-if="['csv', 'url'].includes(form.header_params.input_type)">
                <el-input v-model="form.header_params[selected_template.component_header.format.toLowerCase()]"/>
                <small class="text-danger" v-if="form.header_params.input_type === 'url' && !isValidHeaderMediaUrl">URL not valid</small>
              </b-col>
              <b-col md="9" v-if="form.header_params.input_type == 'file'">
                <b-file :accept="whiteListExtensionHeaderMedia[selected_template.component_header.format.toLowerCase()].join(',')" @change="handlerFileHeaderMedia" v-model="template_header.file"/>
              </b-col>
            </b-row>
          </div>
          <!-- End of Template Header Media Variable -->
          <!-- Template Header Text Variable -->
          <div v-if="selected_template.component_header && selected_template.component_header.format.toLowerCase() == 'text' && selected_template.component_header.header_params.param.length > 0">
            <hr/>
            <b-card-title class="text-capitalize">Template Variables (Header)</b-card-title>
            <b-card-sub-title class="mb-4">Input all variable needed.</b-card-sub-title>
            <b-row>
              <b-col md="3">
                <el-select v-model="form.header_params.input_type">
                  <el-option v-if="form.recipient_type === 'csv'" value="csv" label="CSV Column"/>
                  <el-option value="text" label="Static Text"/>
                </el-select>
              </b-col>
              <b-col md="9">
                <el-input v-model="form.header_params.text" placeholder="Input parameter value"/>
              </b-col>
            </b-row>
          </div>
          <!-- End of Template Header Text Variable -->
          <!-- Template Body Variable -->
          <div v-if="form.body_params.length > 0">
            <hr/>
            <b-card-title class="text-capitalize">Template Variables (Body)</b-card-title>
            <b-card-sub-title class="mb-4">Input all variable needed.</b-card-sub-title>
            <b-row class="mb-2" v-for="(item, i) in selected_template.body_params" :key="i">
              <b-col md="3">
                <el-select v-model="form.body_params[i].type">
                  <el-option v-if="form.recipient_type === 'csv'" value="csv" label="CSV Column"/>
                  <el-option v-if="form.recipient_type === 'group'" value="contact_field" label="Contact Field"/>
                  <el-option value="text" label="Static Text"/>
                </el-select>
              </b-col>
              <b-col md="9">
                <el-select v-if="form.body_params[i].type == 'contact_field' && form.recipient_type == 'group'" v-model="form.body_params[i].text">
                  <el-option value="display_name" label="Name"></el-option>
                  <el-option value="phone_number" label="Phone Number"></el-option>
                </el-select>
                <el-input v-else v-model="form.body_params[i].text" placeholder="Input parameter value"/>
              </b-col>
            </b-row>
          </div>
          <!-- End of Template Body Variable -->
          <!-- Template Button Variable -->
          <div v-if="form.cta_params.length > 0">
            <hr/>
            <b-card-title class="text-capitalize">Template Variables (Button)</b-card-title>
            <b-card-sub-title class="mb-4">Input all variable needed.</b-card-sub-title>
            <b-row class="mb-2" v-for="(item, i) in form.cta_params" :key="i">
              <b-col md="3">
                <el-select v-model="form.cta_params[i].type">
                  <el-option v-if="form.recipient_type === 'csv'" value="csv" label="CSV Column"/>
                  <el-option value="text" label="Static Text"/>
                </el-select>
              </b-col>
              <b-col md="9">
                <el-input v-model="form.cta_params[i].text" :placeholder="label_variables[form.cta_params[i].button]"/>
              </b-col>
            </b-row>
          </div>
          <!-- End of Template Button Variable -->
          <!-- Template Carousel Variable -->
          <div v-if="selected_template.component_carousel">
            <hr/>
            <b-card-title class="text-capitalize">Template Variables (Carousel)</b-card-title>
            <b-card-sub-title class="mb-4">Input all variable needed to all cards.</b-card-sub-title>
            <b-card no-body>
              <b-tabs content-class="m-3" lazy>
                <b-tab v-for="(item_carousel, i) in selected_template.component_carousel.cards" :key="i" :title="'Card ' + (i+1)" @click="setActiveCard(i)">
                  <!-- <b-alert show>I'm lazy mounted!</b-alert> -->
                  <div id="card-content" class="ml-2 mr-2">
                    <div id="body-content">
                      <div v-for="(component, x) in item_carousel.components" :key="x">
                        <div v-if="component.type.toLowerCase()==='header'">
                          <b-card-title class="mt-1 text-capitalize">Header ({{ component.format.toLowerCase() }})</b-card-title>
                          <b-card-sub-title class="mb-2">Input file media to send.</b-card-sub-title>
                          <b-row>
                            <b-col md="3">
                              <el-select @change="handlerHeaderMediaChangeCard(i)" v-model="active_card.header_params.input_type">
                                <el-option value="file" label="File input"/>
                                <el-option value="url" label="URL input"/>
                              </el-select>
                            </b-col>
                            <b-col md="9" v-if="['csv', 'url'].includes(active_card.header_params.input_type)">
                              <el-input v-model="active_card.header_params[active_card.header_params.type]"/>
                              <small class="text-danger" v-if="active_card.header_params.input_type === 'url' && !isValidHeaderMediaUrlCard">URL not valid</small>
                            </b-col>
                            <b-col md="9" v-if="active_card.header_params.input_type == 'file'">
                              <b-file :accept="whiteListExtensionHeaderMedia[component.format.toLowerCase()].join(',')" @change="handlerFileHeaderMediaCarousel($event, i)" v-model="template_header_card[i].file"/>
                              <small class="text-primary" v-if="template_header_card[i].file">File Choosen</small>
                            </b-col>
                          </b-row>
                        </div>
                        <div v-if="component.type.toLowerCase()==='body' && component.example">
                          <b-card-title class="mt-3 text-capitalize">Body</b-card-title>
                          <b-card-sub-title class="mb-2">Input all variable needed.</b-card-sub-title>
                          <b-row class="mb-2" v-for="(item, z) in active_card.body_params" :key="z">
                            <b-col md="3">
                              <el-select v-model="active_card.body_params[z].type">
                                <el-option v-if="form.recipient_type === 'csv'" value="csv" label="CSV Column"/>
                                <el-option v-if="form.recipient_type === 'group'" value="contact_field" label="Contact Field"/>
                                <el-option value="text" label="Static Text"/>
                              </el-select>
                            </b-col>
                            <b-col md="9">
                              <el-select v-if="active_card.body_params[z].type == 'contact_field' && form.recipient_type == 'group'" v-model="active_card.body_params[z].text">
                                <el-option value="display_name" label="Name"></el-option>
                                <el-option value="phone_number" label="Phone Number"></el-option>
                              </el-select>
                              <el-input v-else v-model="active_card.body_params[z].text" placeholder="Input parameter value"/>
                            </b-col>
                          </b-row>
                        </div>
                        <div v-if="component.type.toLowerCase()==='buttons' && active_card.cta_params.length > 0">
                          <b-card-title class="mt-3 text-capitalize">Buttons</b-card-title>
                          <b-card-sub-title class="mb-2">Input all variable needed.</b-card-sub-title>
                          <b-row class="mb-2" v-for="(item, z) in active_card.cta_params" :key="z">
                            <b-col md="3">
                              <el-select v-model="active_card.cta_params[z].type">
                                <el-option v-if="form.recipient_type === 'csv'" value="csv" label="CSV Column"/>
                                <el-option value="text" label="Static Text"/>
                              </el-select>
                            </b-col>
                            <b-col md="9">
                              <el-input v-model="active_card.cta_params[z].text" :placeholder="label_variables[active_card.cta_params[z].button]"/>
                            </b-col>
                          </b-row>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </b-card>
            <hr/>
          </div>
          <!-- End of Template Carousel Variable -->
          <span v-if="form.recipient_type === 'csv'">
            <b-card-title class="text-capitalize">Mandatory CSV Column</b-card-title>
            <b-card-sub-title class="mb-4">These are mandatory column you need on your csv.</b-card-sub-title>
            <div class="w-25">
              <div class="list-group">
                <span v-for="(item, i) in mandatoryHeader" :key="i">
                  <div class="list-group-item" v-if="item.value">
                    <p class="d-flex justify-content-between align-items-center">
                      {{ item.value }}
                      <el-popover
                        placement="right"
                        width="325"
                        trigger="hover">
                        <p v-if="item.isValid === true" style="white-space: pre-line; overflow-wrap: break-word; word-break: break-all;">Good job! This column exists on your csv.</p>
                        <p v-else-if="item.isValid === false" style="white-space: pre-line; overflow-wrap: break-word; word-break: break-all;">This column is required on your csv but not found on your csv. Please fix your csv.</p>
                        <p v-else style="white-space: pre-line; overflow-wrap: break-word; word-break: break-all;">This column is required on your csv.</p>
                        <span slot="reference" class="float-right">
                          <font-awesome v-if="item.isValid === true" icon="check-circle" class="text-success float-right"/>
                          <font-awesome v-else-if="item.isValid === false" icon="exclamation-circle" class="text-danger float-right"/>
                          <font-awesome v-else icon="question-circle" class="text-info float-right"/>
                        </span>
                      </el-popover>
                    </p>
                  </div>
                </span>
              </div>
            </div>
            <hr/>
          </span>
        </b-card>
      </b-col>
      <b-col sm="3">
        <div class="screen">
          <div class="screen-container">
            <div class="chat">
              <div class="chat-container">
                <div class="conversation">
                  <div class="conversation-container vh-100">
                    <h6 class="mt-2">Preview </h6>
                    <div class="table-responsive preview-message received w-100" :style="selected_template.component_carousel ? 'margin-bottom: -8px;' : ''">
                      <span class="mb-2" v-if="previewHeaderContent" style="font-size: 14px; font-weight: bold">{{previewHeaderContent}}<br/></span>
                      <div class="header-media-area mb-2" v-if="selected_template.component_header && selected_template.component_header.format.toLowerCase() !== 'text'">
                        <div v-if="!template_header.base64" class="header-media-content" :style="'background-image: url('+ bg_media[selected_template.component_header.format.toLowerCase()] +')'"/>
                        <div v-else-if="template_header.base64 && selected_template.component_header.format.toLowerCase() == 'image'" class="header-media-filled" :style="'background-image: url('+ template_header.base64 +')'"/>
                        <div v-else-if="template_header.base64 && selected_template.component_header.format.toLowerCase() == 'video'">
                          <iframe width="240" :src="template_header.base64"/>
                        </div>
                        <div v-else-if="template_header.base64 && selected_template.component_header.format.toLowerCase() == 'document'" class="header-media-content" :style="'background-image: url('+ bg_media.document +')'"/>
                      </div>
                      <p class="preview-body mt-2" v-html="previewContent"/>
                      <p class="mt-2 preview-footer" v-if="selected_template.component_footer">{{selected_template.component_footer.text}}<br/></p>
                      <span class="metadata"><span class="time">{{ moment().format('HH:mm') }}</span></span>
                      <div class="cta-area w-100" v-if="selected_template.component_buttons?.buttons && selected_template.component_buttons?.buttons.length <= 3">
                        <div class="cta-button" v-for="(item, i) in selected_template.component_buttons.buttons" :key="i">
                          <p>
                            <font-awesome :icon="item.type.toLowerCase() === 'quick_reply' ? 'reply' : (item.type.toLowerCase() === 'copy_code' ? 'copy' : (item.type.toLowerCase() === 'url' ? 'external-link-alt' : (item.type.toLowerCase() === 'otp' ? 'copy' : 'phone-alt')))"/>
                            &nbsp;{{ item.type.toLowerCase() === 'copy_code' ? 'Copy Coupon Code' : item.text }}
                          </p>
                        </div>
                      </div>
                      <div class="cta-area w-100" v-else-if="selected_template.component_buttons?.buttons.length > 0">
                        <div class="cta-button" v-for="(item, i) in button_list" :key="i">
                          <p>
                            <font-awesome :icon="item.type.toLowerCase() === 'quick_reply' ? 'reply' : (item.type.toLowerCase() === 'copy_code' ? 'copy' : (item.type.toLowerCase() === 'url' ? 'external-link-alt' : (item.type.toLowerCase() === 'otp' ? 'copy' : 'phone-alt')))"/>
                            &nbsp;{{ item.type.toLowerCase() === 'copy_code' ? 'Copy Coupon Code' : item.text }}
                          </p>
                        </div>
                        <div class="cta-button">
                          <a @click="showAllButtons()" class="cursor-pointer">
                            <font-awesome icon="list"/>
                            &nbsp;See all options
                          </a>
                        </div>
                      </div>
                    </div>
                    <Transition name="fade">
                      <div class="preview-button bottom w-100" v-if="loading.allButton">
                        <span style="font-size: 14px; line-height: 1.75rem;">
                          All Options
                          <a @click="showAllButtons()" class="float-right cursor-pointer text-black" style="font-size: 14px;">
                            <font-awesome icon="times"/>
                          </a>
                        </span>
                        <div class="cta-area w-100" v-if="selected_template.component_buttons?.buttons.filter((v) => v.type !== 'quick_reply').length > 0">
                          <div class="cta-button" v-for="(item, i) in selected_template.component_buttons?.buttons.filter((v) => v.type !== 'quick_reply')" :key="i">
                            <p>
                              <font-awesome :icon="item.type === 'quick_reply' ? 'reply' : (item.type === 'copy_code' ? 'copy' : (item.type === 'url' ? 'external-link-alt' : (item.type === 'otp' ? 'copy' : 'phone-alt')))"/>
                              &nbsp;{{ item.type === 'copy_code' ? 'Copy Coupon Code' : item.text }}
                            </p>
                          </div>
                        </div>
                        <div class="cta-area w-100" v-if="selected_template.component_buttons?.buttons.filter((v) => v.type === 'quick_reply').length > 0">
                          <div class="cta-button" v-for="(item, i) in selected_template.component_buttons?.buttons.filter((v) => v.type === 'quick_reply')" :key="i">
                            <p>
                              <font-awesome :icon="item.type === 'quick_reply' ? 'reply' : (item.type === 'copy_code' ? 'copy' : (item.type === 'url' ? 'external-link-alt' : (item.type === 'otp' ? 'copy' : 'phone-alt')))"/>
                              &nbsp;{{ item.type === 'copy_code' ? 'Copy Coupon Code' : item.text }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Transition>
                    <!-- Carousel -->
                    <div v-if="selected_template.component_carousel">
                      <swiper
                        slides-per-view="1"
                        :scrollbar="{ draggable: true }"
                        :pagination="{
                          type: 'fraction',
                        }"
                        :spaceBetween="5"
                        mousewheel
                        freeMode
                        navigation
                      >
                        <swiper-slide v-for="(card, i) in 10" :key="i">
                          <div class="preview-message-carousel received w-100" v-if="card <= selected_template.component_carousel.cards.length">
                            <div v-for="(component, x) in selected_template.component_carousel.cards[i].components" :key="x">
                              <div class="header-media-area mb-2" v-if="component.type.toLowerCase()==='header'">
                                <div v-if="template_header_card[i].base64==''" class="header-media-content" :style="'background-image: url('+ bg_media[component.format.toLowerCase()] +')'"/>
                                <div v-else-if="template_header_card[i].base64 && component.format.toLowerCase() == 'image'" class="header-media-filled" :style="'background-image: url('+ template_header_card[i].base64 +')'"/>
                                <div v-else-if="template_header_card[i].base64 && component.format.toLowerCase() == 'video'" class="header-media-content" :style="'background-image: url('+ bg_media[component.format.toLowerCase()] +')'">
                                  <!-- <iframe width="100%" class="header-media-filled" :src="template_header_card[i].base64"/> -->
                                </div>
                              </div>
                              <p class="preview-body" v-if="component.type.toLowerCase()==='body'" v-html="previewContentCarousel(i, component)"/>
                              <p class="mt-2 preview-footer" v-if="component.type.toLowerCase()==='footer' && previewFooterContentCarousel(i)">{{previewFooterContentCarousel(i)}}<br/></p>
                              <span class="metadata" v-if="x===(selected_template.component_carousel.length-1)"><span class="time">{{ moment().format('HH:mm') }}</span></span>
                              <div class="cta-area w-100" v-if="component.type.toLowerCase()==='buttons' && component.buttons">
                                <div class="cta-button" v-for="(item, y) in component.buttons" :key="y">
                                  <p>
                                    <font-awesome :icon="item.type.toLowerCase() === 'quick_reply' ? 'reply' : (item.type.toLowerCase() === 'copy_code' ? 'copy' : (item.type.toLowerCase() === 'url' ? 'external-link-alt' : (item.type.toLowerCase() === 'otp' ? 'copy' : 'phone-alt')))"/>
                                    &nbsp;{{ item.text }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </swiper-slide>
                        <div class="swiper-scrollbar" slot="scrollbar"></div>
                        <div class="swiper-button-prev" slot="button-prev"></div>
                        <div class="swiper-button-next" slot="button-next"></div>
                      </swiper>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <div class="d-flex flex-row-reverse">
      <el-button @click="sendNotification" :disabled="!isValidStep2" size="small" type="primary" class="ml-2">Send</el-button>
      <el-button @click="onClose" size="small">Close</el-button>
    </div>
  </b-modal>
</template>
<script>
import {
  isEmpty, escapeRegExp, uniq,
} from 'lodash';
import axios from 'axios';
import base64File from '@/library/base64File';
import moment from 'moment';
import { Scrollbar, Mousewheel, Navigation } from 'swiper';
import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2';
import conversationsAPI from '@/api/conversations';
import popupErrorMessages from '@/library/popup-error-messages';
import 'swiper/swiper-bundle.css';

SwiperCore.use([Scrollbar, Mousewheel, Navigation]);
export default {
  name: 'ConvoReachTicket',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    conversation_id: {
      type: String,
    },
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      showModal: false,
      moment,
      loaderStack: 0,
      loader: null,
      templateList: [],
      bg_media: {
        image: 'https://api.media.makna.ai/makna-media/template-header-image.png',
        document: 'https://api.media.makna.ai/makna-media/template-header-document.png',
        video: 'https://api.media.makna.ai/makna-media/template-header-video.png',
      },
      button_list: [],
      footer_category: [
        {
          type: 'none',
          label: 'None',
          group: ['none'],
        },
        {
          type: 'call_to_action',
          label: 'Call To Action',
          group: ['url', 'phone_number', 'copy_code'],
        },
        {
          type: 'quick_reply',
          label: 'Quick Reply',
          group: ['quick_reply'],
        },
      ],
      label_variables: {
        copy_code: 'Input parameter for Coupon Code',
        url: 'Input parameter for URL',
        phone_number: 'Input parameter for Phone Number',
      },
      form: {
        template_name: '',
        template_language: '',
        body_params: [],
        header_params: {},
        cta_params: [],
        cards: [],
      },
      active_card: {
        body_params: [],
        header_params: {},
        cta_params: [],
      },
      selected_template: {
        component_header: null,
        component_body: null,
        component_footer: null,
        component_buttons: null,
        component_carousel: null,
      },
      component_carousel: [],
      template_header: {
        file: null,
        base64: '',
      },
      template_header_card: [],
      whiteListExtensionHeaderMedia: {
        image: ['.jpg', '.png', '.jpeg'],
        video: ['.mp4'],
        document: ['.pdf'],
      },
      loading: {
        step: false,
        allButton: false,
      },
      valid_param: null,
    };
  },
  computed: {
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
    isValidStep2() {
      let is_body_valid = true;
      let is_header_valid = true;
      let is_button_valid = true;
      if (this.form.body_params.length > 0) {
        const findEmpty = this.form.body_params.filter((v) => !v.text);
        if (findEmpty.length) {
          is_body_valid = false;
        }
      }
      if (this.form.header_params?.type) {
        const { type, input_type } = this.form.header_params;
        if (input_type === 'file' && !this.template_header.file) {
          is_header_valid = false;
        } else if (type === 'text' && !this.form.header_params[type] && this.selected_template.component_header?.header_params?.param?.length > 0) {
          is_header_valid = false;
        }
        if (!this.isValidHeaderMediaUrl) {
          is_header_valid = false;
        }
      }

      if (this.form.cta_params.length > 0) {
        const findEmpty = this.form.cta_params.filter((v) => !v.text);
        if (findEmpty.length) {
          is_button_valid = false;
        }
      }
      return is_header_valid && is_body_valid && is_button_valid && this.form.template_name && this.form.template_language && this.isValidCard;
    },
    isValidHeaderMediaUrl() {
      /* eslint-disable no-useless-escape */
      const regex_url = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/ig;
      const { type, input_type } = this.form.header_params;
      if (input_type === 'url') {
        return regex_url.test(this.form.header_params[type]);
      }
      return true;
    },
    isValidHeaderMediaUrlCard() {
      /* eslint-disable no-useless-escape */
      const regex_url = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/ig;
      const { type, input_type } = this.active_card.header_params;
      if (input_type === 'url') {
        return regex_url.test(this.active_card.header_params[type]);
      }
      return true;
    },
    previewHeaderContent() {
      if (this.selected_template.component_header?.format.toLowerCase() === 'text') {
        if (this.form.header_params.text) {
          return this.selected_template.component_header?.text.replace('{{1}}', this.form.header_params.text);
        }
        return this.selected_template.component_header?.text;
      }
      return '';
    },
    previewContent() {
      if (this.selected_template.component_body) {
        let string = this.selected_template.component_body.text;
        const htmlFormat = [
          { symbol: '*', tag: 'b' },
          { symbol: '_', tag: 'em' },
          { symbol: '~', tag: 'del' },
          { symbol: '```', tag: 'tt' },
          { symbol: '`', tag: 'mark' },
        ];

        this.selected_template.body_params.forEach((v, i) => {
          const replaceRegex = new RegExp(escapeRegExp(v), 'ig');
          const replace_text = this.form.body_params[i].text;
          if (replace_text) {
            string = string.replace(replaceRegex, replace_text);
          }
        });

        htmlFormat.forEach(({ symbol, tag }) => {
          if (!string) return;
          const regex = new RegExp(`\\${symbol}([^${symbol}]*)\\${symbol}`, 'gm');
          const match = string.match(regex);
          if (!match) return;
          match.forEach((m) => {
            let formatted = m;
            /* eslint-disable no-plusplus */
            for (let i = 0; i < 2; i++) {
              formatted = formatted.replace(symbol, `<${i > 0 ? '/' : ''}${tag}>`);
            }
            string = string.replace(m, formatted);
          });
        });
        return string;
      }
      return '';
    },
    isValidCard() {
      const empty_params = [];
      if (this.form.cards.length) {
        this.form.cards.forEach((val) => {
          if (val.body_params.length) {
            val.body_params.forEach((val_body) => {
              if (!val_body.text) {
                empty_params.push(val_body);
              }
            });
          }
          if (val.cta_params.length) {
            val.cta_params.forEach((val_cta) => {
              if (!val_cta.text) {
                empty_params.push(val_cta);
              }
            });
          }
        });
      }
      if (this.template_header_card.length) {
        this.template_header_card.forEach((val, index) => {
          if (!val.base64) {
            empty_params.push(this.form.cards[index].header_params);
          }
        });
      }
      return !empty_params.length;
    },
  },
  methods: {
    onClose() {
      this.$emit('onClose');
    },
    resetChat() {
      this.$emit('resetChat');
    },
    showLoader() {
      if (this.loaderStack === 0) {
        this.loader = this.$loading.show();
      }
      this.loaderStack += 1;
    },
    hideLoader() {
      this.loaderStack -= 1;
      if (this.loaderStack === 0) {
        this.loader.hide();
      }
    },
    async setActiveCard(key) {
      this.active_card = this.form.cards[key];
    },
    previewContentCarousel(key, component) {
      // if (this.selected_template.component_body) {
      let string = component.text;
      const htmlFormat = [
        { symbol: '*', tag: 'b' },
        { symbol: '_', tag: 'em' },
        { symbol: '~', tag: 'del' },
        { symbol: '```', tag: 'tt' },
        { symbol: '`', tag: 'mark' },
      ];
      const component_carousel_body = component;
      const body_carousel_params = uniq(component_carousel_body.text.match(/({{\d{1,}}})/ig)) || [];
      body_carousel_params.forEach((v, i) => {
        const replaceRegex = new RegExp(escapeRegExp(v), 'ig');
        const replace_text = this.form.cards[key].body_params[i].text;
        if (replace_text) {
          string = string.replace(replaceRegex, replace_text);
        }
      });

      htmlFormat.forEach(({ symbol, tag }) => {
        if (!string) return;
        const regex = new RegExp(`\\${symbol}([^${symbol}]*)\\${symbol}`, 'gm');
        const match = string.match(regex);
        if (!match) return;
        match.forEach((m) => {
          let formatted = m;
          /* eslint-disable no-plusplus */
          for (let i = 0; i < 2; i++) {
            formatted = formatted.replace(symbol, `<${i > 0 ? '/' : ''}${tag}>`);
          }
          string = string.replace(m, formatted);
        });
      });
      return string;
      // }
      // return '';
    },
    previewFooterContentCarousel(component) {
      return component.text;
    },
    checkFirstButton(type) {
      const content = this.footer_category.find((v) => v.group.includes(type));
      return content.type;
    },
    buttonList() {
      let content = [];
      const component_buttons = this.selected_template.components.find((v) => v.type.toLowerCase() === 'buttons');
      if (component_buttons?.buttons.length > 3) {
        content = component_buttons.buttons.filter((v) => this.checkFirstButton(v.type.toLowerCase()) === this.checkFirstButton(component_buttons.buttons[0].type.toLowerCase())).slice(0, 2);
        if (content.length === 2) {
          this.button_list = content;
        } else {
          this.button_list[0] = component_buttons.buttons[0];
          this.button_list[1] = component_buttons.buttons[1];
        }
      }
    },
    showAllButtons() {
      this.loading.allButton = !this.loading.allButton;
    },
    async getTemplate() {
      await conversationsAPI.getReachTicketTemplates(this.activeWorkspace._id, this.conversation_id)
        .then(async (res) => {
          await popupErrorMessages(res);
          this.templateList = res.data.templates.filter((v) => v.status?.toLowerCase() === 'approved').map((v) => {
            v.template_name_str = `${v.name} (${v.language})`;
            return v;
          });
        })
        .catch(() => {});
    },
    handlerChangeTemplate(value) {
      this.loading.allButton = false;
      this.form.header_params = {};
      this.form.body_params = [];
      this.form.cta_params = [];
      this.form.cards = [];
      this.template_header = {
        file: null,
        base64: '',
      };
      this.template_header_card = [];
      this.selected_template = this.templateList.find((v) => v.id === value);
      this.form.template_name = this.selected_template.name;
      this.form.template_language = this.selected_template.language;
      const component_body = this.selected_template.components.find((v) => v.type.toLowerCase() === 'body');
      const component_header = this.selected_template.components.find((v) => v.type.toLowerCase() === 'header');
      if (component_header) {
        const format = component_header?.format?.toLowerCase();
        this.form.header_params = {
          input_type: 'file',
          [format]: '',
          type: format,
        };
        component_header.header_params = {};
      }
      if (component_header?.format?.toLowerCase() === 'text') {
        this.form.header_params = {
          input_type: 'text',
          type: 'text',
          text: '',
        };
        component_header.header_params = {
          type: 'text',
          param: component_header.text.match(/({{\d{1,}}})/ig) || [],
        };
      }
      const component_footer = this.selected_template.components.find((v) => v.type.toLowerCase() === 'footer');
      const component_buttons = this.selected_template.components.find((v) => v.type.toLowerCase() === 'buttons');
      if (component_buttons) {
        const btn_url = component_buttons.buttons.filter((v) => v.type.toLowerCase() === 'url');
        if (btn_url) {
          const url_dinamic = btn_url.filter((v) => v.url.match(/({{\d{1,}}})/ig));
          if (url_dinamic.length > 0) {
            this.form.cta_params = [];
            url_dinamic.forEach(() => {
              this.form.cta_params.push({
                button: 'url',
                type: 'text',
                text: '',
              });
            });
          }
        }
        const btn_copy = component_buttons.buttons.find((v) => v.type.toLowerCase() === 'copy_code');
        if (btn_copy) {
          if (this.form.cta_params?.length > 0) {
            this.form.cta_params.push({
              button: 'copy_code',
              type: 'text',
              text: '',
            });
          } else {
            this.form.cta_params = [{
              button: 'copy_code',
              type: 'text',
              text: '',
            }];
          }
        }
        const quick_reply_btn = component_buttons.buttons.filter((v) => v.type.toLowerCase() === 'quick_reply');
        if (quick_reply_btn.length) {
          this.form.cta_payload = [];
          quick_reply_btn.forEach((val) => {
            this.form.cta_payload.push({
              button: 'quick_reply',
              type: 'text',
              text: '',
              placeholder: `Input payload for button ${val.text}`,
            });
          });
        }
      }
      const body_params = uniq(component_body.text.match(/({{\d{1,}}})/ig)) || [];
      if (body_params) this.form.body_params = body_params.map(() => ({ type: 'text', text: '' }));

      const component_carousel = this.selected_template.components.find((v) => v.type.toLowerCase() === 'carousel');
      if (component_carousel) {
        component_carousel.cards.forEach((v, index) => {
          this.template_header_card[index] = {
            file: null,
            base64: '',
          };
          const component_carousel_header = v.components.find((v1) => v1.type.toLowerCase() === 'header');
          const format = component_carousel_header?.format?.toLowerCase();
          const card_header_params = {
            input_type: 'file',
            [format]: '',
            type: format,
          };
          const component_carousel_buttons = v.components.find((v1) => v1.type.toLowerCase() === 'buttons');
          const card_cta_params = [];
          if (component_carousel_buttons) {
            const btn_param = component_carousel_buttons.buttons.filter((v1) => v1.type.toLowerCase() === 'url' && v1.url.match(/({{\d{1,}}})/ig));
            btn_param.forEach(() => {
              card_cta_params.push({
                button: 'url',
                type: 'text',
                text: '',
              });
            });
          }
          const component_carousel_body = v.components.filter((v1) => v1.type.toLowerCase() === 'body' && v1.text.match(/({{\d{1,}}})/ig));
          const card_body_params = [];
          if (component_carousel_body) {
            component_carousel_body.forEach(() => {
              card_body_params.push({
                type: 'text',
                text: '',
              });
            });
          }
          this.form.cards[index] = {
            header_params: card_header_params,
            body_params: card_body_params,
            cta_params: card_cta_params,
          };
          this.active_card = this.form.cards[index];
        });
        this.active_card = this.form.cards[0];
      }

      /* eslint-disable prefer-object-spread */
      this.selected_template = Object.assign({
        component_body,
        body_params,
        component_header,
        component_footer,
        component_buttons,
        component_carousel,
      }, this.selected_template);
      this.buttonList();
    },
    async handlerFileHeaderMedia(event) {
      const file = event.target.files[0];
      if (file) {
        this.template_header.base64 = await base64File(file);
      }
    },
    async handlerFileHeaderMediaCarousel(event, key) {
      const file = event.target.files[0];
      if (file) {
        // this.template_header_card[key].base64 = await base64File(file);
        this.$set(this.template_header_card, key, { base64: await base64File(file), file });
      }
    },
    handlerHeaderMediaChange() {
      this.template_header = {
        base64: '',
        file: null,
      };
    },
    handlerHeaderMediaChangeCard(key) {
      this.template_header_card[key] = {
        file: null,
        base64: '',
      };
    },
    async validateContentTypeMediaURL() {
      const { type } = this.form.header_params;
      let valid_content_type = ['image/jpeg', 'image/jpg', 'image/png'];
      if (type === 'video') {
        valid_content_type = ['video/mp4', 'video/3gpp'];
      } else if (type === 'document') {
        return true;
      }
      try {
        const response = await axios({
          url: this.form.header_params[type],
          responseType: 'arraybuffer',
          responseEncoding: 'binary',
        });
        const isValid = valid_content_type.includes(response.headers['content-type'].split(';')[0]);
        if (isValid) {
          this.template_header.base64 = this.form.header_params[type];
        }
        return isValid;
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    async validateContentTypeMediaURLCarousel(key) {
      const { type } = this.form.cards[key].header_params;
      let valid_content_type = ['image/jpeg', 'image/jpg', 'image/png'];
      if (type === 'video') {
        valid_content_type = ['video/mp4', 'video/3gpp'];
      } else if (type === 'document') {
        return true;
      }
      try {
        const response = await axios({
          url: this.form.cards[key].header_params[type],
          responseType: 'arraybuffer',
          responseEncoding: 'binary',
        });
        const isValid = valid_content_type.includes(response.headers['content-type'].split(';')[0]);
        if (isValid) {
          this.template_header_card[key].base64 = this.form.cards[key].header_params[type];
        }
        return isValid;
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    resetAll() {
      this.form = {
        template_name: '',
        template_language: '',
        body_params: [],
        header_params: {},
        cta_params: [],
        cards: [],
      };
      this.templateList = [];
      this.template_header = {
        file: null,
        base64: '',
      };
      this.template_header_card = [];
      this.selected_template = {
        component_header: null,
        component_body: null,
        component_footer: null,
        component_buttons: null,
        component_carousel: null,
      };
    },
    async sendNotification() {
      // if (this.form.header_params?.input_type === 'file' && this.template_header?.base64) {
      //   this.form.header_params[this.form.header_params.type] = this.template_header.base64;
      // }
      if (this.form.header_params.type !== 'text') {
        if (this.form.header_params.input_type === 'file') {
          this.form.header_params[this.form.header_params.type] = this.template_header.base64;
        }
        if (this.form.header_params.input_type === 'file' && this.form.header_params.type === 'document') {
          this.form.header_params.filename = this.template_header.file.name;
        }
      }
      // carousel cards
      if (this.form.cards) {
        this.form.cards.forEach(async (v1, index) => {
          if (v1.header_params && v1.header_params.input_type === 'url') {
            this.showLoader();
            const is_valid = await this.validateContentTypeMediaURLCarousel(index);
            this.hideLoader();
            if (!is_valid) {
              this.$message({
                type: 'warning',
                message: 'Media URL is not valid',
              });
              return;
            }
          }
          if (this.form.cards[index].header_params.type !== 'text') {
            if (this.form.cards[index].header_params.input_type === 'file') {
              this.form.cards[index].header_params[this.form.cards[index].header_params.type] = this.template_header_card[index].base64;
            }
            if (this.form.cards[index].header_params.input_type === 'file' && this.form.cards[index].header_params.type === 'document') {
              this.form.cards[index].header_params.filename = this.template_header_card[index].file.name;
            }
          }
        });
      }
      this.$confirm(this.$t('conversations.reach_ticket.confirmation'), this.$t('general.confirmation'), {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        center: true,
        /* eslint-disable no-param-reassign */
        beforeClose: async (action, instance, cb) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            // const form_data = JSON.parse(JSON.stringify(this.form));
            // delete form_data.cta_params;
            const response = await conversationsAPI.reachTicket({
              workspace_id: this.activeWorkspace._id,
              conversation_id: this.conversation_id,
              data: this.form,
              // data: form_data,
            }).catch(() => {});
            instance.confirmButtonLoading = false;
            await popupErrorMessages(response);
            const errormsg = response.error;
            if (isEmpty(errormsg)) {
              this.$message({
                type: 'success',
                message: this.$t('conversations.reach_ticket.success'),
                duration: 30 * 1000,
                showClose: true,
              });
              this.resetAll();
              cb();
              this.onClose();
              this.resetChat();
            } else {
              this.$message({
                title: this.$t('general.error.general'),
                type: 'error',
                message: response.error,
                duration: 30 * 1000,
                showClose: true,
              });
            }
          }
          cb();
        },
      });
    },
  },
  watch: {
    async show() {
      this.showModal = this.show;
      if (this.show) {
        this.showLoader();
        this.resetAll();
        await this.getTemplate();
        this.hideLoader();
      }
    },
  },
};
</script>

<style scoped>
/* .dropify-wrapper .dropify-message p {
  font-size: 16px;
  color: #686868;
} */
.screen {
  text-align: left;
}
.screen-container {
  height: 100%;
}

/* Chat */

.chat {
  height: calc(100% - 69px);
}

.chat-container {
  height: 100%;
}

/* Conversation */

.conversation {
  height: calc(100% - 12px);
  position: relative;
  background: #efe7dd url("https://cloud.githubusercontent.com/assets/398893/15136779/4e765036-1639-11e6-9201-67e728e86f39.jpg") repeat;
  z-index: 0;
}

.conversation ::-webkit-scrollbar {
  transition: all .5s;
  width: 5px;
  height: 1px;
  z-index: 10;
}

.conversation ::-webkit-scrollbar-track {
  background: transparent;
}

.conversation ::-webkit-scrollbar-thumb {
  background: #b3ada7;
}

.conversation .conversation-container {
  /* height: calc(100% - 68px); */
  min-height: 200px;
  /* box-shadow: inset 0 10px 10px -10px #000000; */
  overflow-x: hidden;
  padding: 0 16px;
  margin-bottom: 5px;
}

.conversation .conversation-container:after {
  content: "";
  display: table;
  clear: both;
}

/* Messages */

.preview-message {
  color: #000;
  clear: both;
  line-height: 18px;
  font-size: 12px;
  padding: 8px;
  position: relative;
  margin: 8px 0;
  max-width: 265px;
  word-wrap: break-word;
  z-index: -1;
}

.preview-message-carousel {
  color: #000;
  clear: both;
  line-height: 18px;
  font-size: 12px;
  padding: 8px;
  position: relative;
  margin: 8px 0;
  max-width: 265px;
  word-wrap: break-word;
  /* z-index: -1; */
}

.preview-message:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
}

.button-area {
  color: #000;
  clear: both;
  line-height: 18px;
  font-size: 12px;
  padding: 8px 0px;
  position: relative;
  word-wrap: break-word;
  z-index: -1;
  max-width: 265px;
  border-radius: 5px;
  float: left;
}

.reply-button {
  color: #5591EC;
  clear: both;
  line-height: 18px;
  font-size: 12px;
  padding: 8px;
  position: relative;
  word-wrap: break-word;
  z-index: -1;
  background: #fff;
  border-radius: 5px;
  float: left;
  text-align: center;
}

.cta-area {
  border-top: 1px solid #dadde1;
  /* margin: 0 7px 0 9px; */
  margin-top: 8px;
  float: right;
  background: #fff;
}

.cta-button {
  color: #00a5f4;
  font-size: 12px;
  height: 24px;
  line-height: 20px;
  padding: 0 16px;
  white-space: pre-wrap;
  text-align: center;
  justify-content: center;
  flex-direction: row;
  display: flex;
  word-wrap: break-word;
  margin-top: 5%;
}

.header-media-area {
  padding: 3px 3px 0 3px;
}

.header-media-content {
  background-color: #ccd0d5;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 80px 80px;
  border-radius: 4px;
  box-sizing: border-box;
}

.header-media-filled {
  background-color: #ccd0d5;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  border-radius: 4px;
  box-sizing: border-box;
  /* width: 240px; */
}

.header-media-content::after {
  content: '';
  display: block;
  padding-top: calc(100% * (1 / 1.91));
}

.header-media-filled::after {
  content: '';
  display: block;
  padding-top: calc(100% * (1 / 1.91));
}

.header-media-image {
  background-image: url('https://api.media.makna.ai/makna-media/template-header-image.png');
}

.metadata {
  display: inline-block;
  float: right;
  padding: 0 0 0 7px;
  position: relative;
  bottom: -4px;
}

.metadata .time {
  color: rgba(0, 0, 0, .45);
  font-size: 11px;
  display: inline-block;
}

.metadata .tick {
  display: inline-block;
  margin-left: 2px;
  position: relative;
  top: 4px;
  height: 16px;
  width: 16px;
}

.metadata .tick svg {
  position: absolute;
  transition: .5s ease-in-out;
}

.metadata .tick svg:first-child {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform: perspective(800px) rotateY(180deg);
          transform: perspective(800px) rotateY(180deg);
}

.metadata .tick svg:last-child {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform: perspective(800px) rotateY(0deg);
          transform: perspective(800px) rotateY(0deg);
}

.metadata .tick-animation svg:first-child {
  -webkit-transform: perspective(800px) rotateY(0);
          transform: perspective(800px) rotateY(0);
}

.metadata .tick-animation svg:last-child {
  -webkit-transform: perspective(800px) rotateY(-179.9deg);
          transform: perspective(800px) rotateY(-179.9deg);
}

.preview-message:first-child {
  margin: 16px 0 8px;
}

.preview-message.received {
  background: #fff;
  border-radius: 0px 5px 5px 5px;
  float: left;
}

.preview-message.received .metadata {
  padding: 0 0 0 16px;
}

.preview-message.received:after {
  border-width: 0px 10px 10px 0;
  border-color: transparent #fff transparent transparent;
  top: 0;
  left: -10px;
}

.preview-message.sent {
  background: #e1ffc7;
  border-radius: 5px 0px 5px 5px;
  float: right;
}

.preview-message.sent:after {
  border-width: 0px 0 10px 10px;
  border-color: transparent transparent transparent #e1ffc7;
  top: 0;
  right: -10px;
}

.preview-message-carousel:first-child {
  margin: 16px 0 8px;
}

.preview-message-carousel.received {
  background: #fff;
  border-radius: 5px 5px 5px 5px;
  float: left;
  /* left: -10px; */
}

.preview-message-carousel.received .metadata {
  padding: 0 0 0 16px;
}

.preview-message-carousel.received:after {
  border-width: 0px 10px 10px 0;
  border-color: transparent #fff transparent transparent;
  top: 0;
  content: '';
}

.preview-message-carousel.sent {
  background: #e1ffc7;
  border-radius: 5px 0px 5px 5px;
  float: right;
}

.preview-message-carousel.sent:after {
  border-width: 0px 0 10px 10px;
  border-color: transparent transparent transparent #e1ffc7;
  top: 0;
  right: -10px;
}

.preview-message-carousel.received.carousel-message {
  border-radius: 5px;
}
.preview-message-carousel.received.carousel-message img {
  width: 100%;
  height: auto;
  border-radius: 5px;
}
.preview-message-carousel.received img {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.preview-footer {
  font-size: 11px;
  color: rgba(0, 0, 0, .45);
  word-wrap: break-word;
  white-space: pre-wrap;
}

.preview-body {
  font-size: 12px;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.preview-body-carousel {
  font-size: 12px;
  word-wrap: break-word;
  white-space: pre-wrap;
  padding: 0 4px 0 4px;
}

.row-button {
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  display: flex;
}

.col-button {
  background-color: #fff;
  border-radius: 7.5px;
  box-shadow: 0 1px 0.5px rgb(0 0 0 / 15%);
  box-sizing: border-box;
  flex-grow: 1;
  margin: 2px 0 0 2px;
  min-width: calc(50% - 2px);
  color: #00a5f4;
  font-size: 14px;
  height: 34px;
  line-height: 20px;
  padding: 0 16px;
  white-space: pre-wrap;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  display: flex;
}

.swiper-slide {
  display: flex !important;
  justify-content: flex-start !important;
  flex-direction: column !important;
  /* width: 202px !important; */
  /* width: 100% !important; */
  min-width: 202px;
}
.swiper-wrapper {
  display: flex;
  /* width: 100px !important; */
  width: 980px !important;
  /* min-width: 300px; */
  /* width: auto; */
}
.swiper-container {
  height: 450px !important;
  width: 100% !important;
}

/* Small Screens */

@media (max-width: 768px) {
  .marvel-device.nexus5 {
    border-radius: 0;
    flex: none;
    padding: 0;
    max-width: none;
    overflow: hidden;
    height: 100%;
    width: 100%;
  }

  .marvel-device > .screen .chat {
    visibility: visible;
  }

  .marvel-device {
    visibility: hidden;
  }

  .screen-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  /* .conversation {
    height: calc(100vh - 55px);
  }
  .conversation .conversation-container {
    height: calc(100vh - 120px);
  } */
}
</style>
