<template>
  <div class="page-content" ref="container">
    <div class="d-flex justify-content-between mb-4">
      <form class="search-form" @submit.prevent="searchFilter()">
        <div class="input-group border rounded-sm">
          <div class="input-group-prepend">
            <div class="input-group-text border-0 rounded-sm">
              <FeatherIcon type="search" class="icon-md cursor-pointer" />
            </div>
          </div>
          <input v-model="query.user_name" type="text" class="form-control  border-0 rounded-sm" id="searchForm" placeholder="Search user here...">
        </div>
      </form>
      <button class="btn btn-outline-primary" @click="addSchedule">
        <font-awesome icon="plus"/> Add Schedule
      </button>
    </div>
    <vc-calendar
      class="custom-calendar max-w-full"
      :masks="masks"
      :attributes="attributes"
      disable-page-swipe
      is-expanded
      @update:from-page="onPageChange"
    >
      <template v-slot:day-content="{ day, attributes }">
        <div class="flex flex-col h-full z-10 overflow-hidden">
          <span class="day-label text-sm text-gray-900">{{ day.day }}</span>
          <div class="flex-row flex-grow overflow-y-auto overflow-x-auto">
            <div v-for="attr in attributes" :key="attr.key">
              <p
                class="text-xs leading-tight rounded-sm p-1 mt-0 mb-1 cursor-pointer"
                :class="attr.customData.class"
                @click="onClickShowDetail(attr.customData.schedule)">
                {{ attr.customData.title }}
              </p>
              <br/>
            </div>
          </div>
        </div>
      </template>
    </vc-calendar>
    <!-- <b-modal> -->
    <el-dialog
      title="Add New Schedule"
      :visible.sync="modal.add"
      width="50%">
      <el-form label-width="120px" label-position="left" @reset="closeModal" class="full-width mb-5">
        <el-form-item label="Event Name">
          <el-input v-model="form.name" placeholder="Please enter event name"></el-input>
        </el-form-item>
        <el-form-item label="Choose Lead">
          <el-select v-model="form.lead_id" filterable remote reserve-keyword :remote-method="searchLead"
          placeholder="Please enter a keyword" :loading="loading.get_leads" style="width: 100%">
            <el-option
              v-for="item in filter_leads"
              :key="item.id"
              :label="item.label"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Another Teams">
          <el-select v-model="form.team_ids" multiple filterable remote reserve-keyword :remote-method="searchUser"
          placeholder="Please enter a keyword" :loading="loading.get_users" style="width: 100%">
            <el-option
              v-for="item in filter_users"
              :key="item.id"
              :label="item.text"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Schedule at">
          <el-date-picker
            v-model="form.schedule_at"
            type="datetime"
            width="100%"
            placeholder="Pick date"
            style="width: 100%"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="Description">
          <el-input v-model="form.description" type="textarea" placeholder="Please enter description (optional)"></el-input>
        </el-form-item>
        <el-form-item class="float-right">
          <el-button size="small" type="primary" @click="createSchedule" :loading="loading.submit">Submit</el-button>
          <el-button size="small" @click="closeModal">Cancel</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      title="Edit Schedule"
      :visible.sync="modal.update"
      width="50%">
      <el-form label-width="120px" label-position="left" @reset="closeModal" class="full-width mb-5">
        <el-form-item label="Event Name">
          <el-input v-model="form.name" placeholder="Please enter event name"></el-input>
        </el-form-item>
        <el-form-item label="Choose Lead">
          <el-select v-model="form.lead_id" filterable remote reserve-keyword :remote-method="searchLead"
          placeholder="Please enter a keyword" :loading="loading.get_leads" style="width: 100%">
            <el-option
              v-for="item in filter_leads"
              :key="item.id"
              :label="item.label"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Another Teams">
          <el-select v-model="form.team_ids" multiple filterable remote reserve-keyword :remote-method="searchUser"
          placeholder="Please enter a keyword" :loading="loading.get_users" style="width: 100%">
            <el-option
              v-for="item in filter_users"
              :key="item.id"
              :label="item.text"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Schedule at">
          <el-date-picker
            v-model="form.schedule_at"
            type="datetime"
            width="100%"
            placeholder="Pick date"
            style="width: 100%"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="Description">
          <el-input v-model="form.description" type="textarea" placeholder="Please enter description (optional)"></el-input>
        </el-form-item>
        <el-form-item class="float-right">
          <el-button size="small" type="primary" @click="updateSchedule" :loading="loading.submit">Submit</el-button>
          <el-button size="small" @click="closeModal">Cancel</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <schedule-info
    :show-modal="modal.info"
    :schedule="schedule_detail"
    @onClose="handlerCloseDetail"
    @onEdit="onClickEdit"
    />
    <!-- </b-modal> -->
  </div>
</template>
<script>
import _ from 'lodash';
import moment from 'moment';
import popupErrorMessages from '@/library/popup-error-messages';
import leadsAPI from '../../../api/leads';
import usersAPI from '../../../api/users';
import ScheduleInfo from '../../components/leads/ScheduleInfo.vue';

export default {
  name: 'LeadsSchedule',
  components: {
    ScheduleInfo,
  },
  metaInfo: {
    title: 'Meeting Schedules',
  },
  data() {
    return {
      masks: {
        weekdays: 'WWW',
      },
      modal: {
        add: false,
        update: false,
        info: false,
      },
      loading: {
        get_users: false,
        get_leads: false,
        submit: false,
      },
      schedule_id: '',
      form: {
        name: '',
        lead_id: '',
        schedule_at: '',
        team_ids: [],
        description: '',
      },
      list: [],
      filter_users: [],
      filter_leads: [],
      schedule_detail: {},
      attributes: [],
      query: {
        start_date: moment().startOf('month').format(''),
        end_date: moment().endOf('month').format(''),
        user_name: '',
      },
      loaderStack: 0,
      loader: null,
      relation: {
        am: 'am',
        ar: 'sy',
        az: 'az',
        bn: 'bd',
        be: 'be',
        ca: 'es-ca',
        cs: 'cz',
        de: 'de',
        el: 'gr',
        en: 'gb',
        es: 'es',
        et: 'et',
        fa: 'ir',
        fr: 'fr',
        bg: 'bg',
        ha: 'ng',
        hi: 'in',
        hu: 'hu',
        hy: 'am',
        it: 'it',
        ja: 'jp',
        jv: 'id',
        km: 'km',
        ko: 'kr',
        lv: 'lv',
        mr: 'in',
        ms: 'my',
        pl: 'pl',
        pt: 'pt',
        ro: 'ro',
        ru: 'ru',
        sw: 'ke',
        ta: 'lk',
        te: 'in',
        th: 'th',
        tr: 'tr',
        uk: 'ua',
        uz: 'uz',
        vi: 'vn',
        zh: 'cn',
      },
    };
  },
  computed: {
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
  },
  mounted() {
    // this.getList();
  },
  methods: {
    showLoader() {
      if (this.loaderStack === 0) {
        this.loader = this.$loading.show();
      }
      this.loaderStack += 1;
    },
    hideLoader() {
      this.loaderStack -= 1;
      if (this.loaderStack === 0) {
        this.loader.hide();
      }
    },
    closeModal() {
      this.modal = {
        add: false,
        update: false,
        delete: false,
        info: false,
      };
    },
    addSchedule() {
      this.modal.add = true;
    },
    async searchUser(keyword = '') {
      this.filter_users = [];
      this.loading.get_users = true;
      await usersAPI.getList(1, keyword).then((res) => {
        this.filter_users = res.data.rows.map((v) => {
          const x = {
            id: v.id,
            text: `${v.name}`,
          };
          return x;
        });
      }).catch((err) => {
        console.log(err);
      });
      this.loading.get_users = false;
    },
    async searchLead(keyword) {
      this.filter_leads = [];
      if (keyword?.length > 2) {
        this.loading.get_leads = true;
        await leadsAPI.searchLeads({
          workspace_id: this.activeWorkspace._id,
          keyword,
        }).then((res) => {
          this.filter_leads = res.data.map((v) => {
            const x = {
              id: v.id,
              label: v.name,
            };
            return x;
          });
        }).catch((err) => {
          console.log(err);
        });
        this.loading.get_leads = false;
      }
    },
    async getList() {
      this.showLoader();
      const { data: response } = await leadsAPI.get_schedule(this.activeWorkspace._id, this.query);
      this.list = response;
      if (this.list.length) {
        await this.searchFilter();
      }
      this.hideLoader();
    },
    async searchFilter() {
      this.attributes = this.list.map((v, i) => {
        v.scheduled_at_str = `${moment(v.schedule_at).format('DD MMM YYYY HH:mm')}`;
        v.created_at_str = moment(v.created_at).format('DD MMM YYYY, HH:mm');
        v.status_str = v.step_name;
        if (v.status) {
          v.status_str = v.status.toUpperCase();
        }
        if (v.user_contributed.length > 0) {
          v.user_contributed_str = v.user_contributed.map((x) => (x.name));
        }
        if (v.type === 'lead') {
          v.class = 'badge badge-success';
        } else {
          v.class = 'badge badge-warning';
        }
        let exist = true;
        if (this.query.user_name) {
          if (v.user.name.toLowerCase().includes(this.query.user_name.toLowerCase())) {
            exist = true;
          } else if (v.user_contributed_str) {
            const exist_team = v.user_contributed_str.filter((z) => z.toLowerCase().includes(this.query.user_name.toLowerCase()));
            if (exist_team.length === 0) {
              exist = false;
            }
          } else {
            exist = false;
          }
        }
        if (exist === true) {
          return {
            key: i,
            customData: {
              title: `${moment(v.schedule_at).format('HH:mm')} ${v.name}`,
              class: v.class,
              schedule: v,
            },
            dates: moment(v.schedule_at).format(),
          };
        }
        return {};
      });
    },
    createSchedule() {
      this.loading.submit = true;
      this.showLoader();
      leadsAPI.create_schedule({
        workspace_id: this.activeWorkspace._id,
        data: this.form,
      }).then(async (res) => {
        this.hideLoader();
        this.loading.submit = false;
        await popupErrorMessages(res);
        this.closeModal();
        this.$message({
          message: this.$t('leads.success.lead.add'),
          type: 'success',
        });
        this.getList();
      }).catch(async () => {
        this.hideLoader();
        this.loading.submit = false;
      });
    },
    updateSchedule() {
      this.loading.submit = true;
      this.showLoader();
      leadsAPI.update_schedule({
        workspace_id: this.activeWorkspace._id,
        id: this.schedule_id,
        data: this.form,
      }).then(async (res) => {
        this.hideLoader();
        this.loading.submit = false;
        await popupErrorMessages(res);
        this.closeModal();
        this.$message({
          message: this.$t('leads.success.lead.update'),
          type: 'success',
        });
        this.getList();
      }).catch(async () => {
        this.hideLoader();
        this.loading.submit = false;
      });
    },
    deleteLeads(dt) {
      this.$confirm(`Are you sure want to delete ${dt.name} lead?`, 'Delete Lead', {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        center: true,
        /* eslint-disable no-param-reassign */
        beforeClose: async (action, instance, cb) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            leadsAPI.delete_leads({
              workspace_id: this.activeWorkspace._id,
              id: dt.id,
            }).then(async (res) => {
              this.hideLoader();
              await popupErrorMessages(res);
              this.closeModal();
              this.$message({
                message: this.$t('leads.success.lead.delete'),
                type: 'success',
              });
              this.loadData();
            }).catch(async () => {
              this.hideLoader();
            });
          }
          instance.confirmButtonLoading = false;
          cb();
        },
      }).catch(() => {});
    },
    onPageChange(value) {
      const start_value = moment().month(value.month - 1).year(value.year).startOf('month')
        .format();
      const end_value = moment().month(value.month - 1).year(value.year).endOf('month')
        .format();
      this.query = {
        start_date: start_value,
        end_date: end_value,
      };
      this.getList();
    },
    onClickEdit() {
      this.schedule_id = this.schedule_detail.id;
      const sch = _.cloneDeep(this.schedule_detail);
      if (this.schedule_detail.user_contributed.length > 0) {
        this.filter_users = this.schedule_detail.user_contributed.map((v) => {
          const x = {
            id: v.id,
            text: v.name,
          };
          return x;
        });
      }
      this.filter_leads = [{
        id: this.schedule_detail.leads_board.id,
        label: this.schedule_detail.leads_board.name,
      }];
      this.form = {
        name: sch.name,
        lead_id: sch.lead_id,
        schedule_at: moment(sch.schedule_at).format('YYYY-MM-DD HH:mm:ss'),
        team_ids: sch.team_ids,
        description: sch.description,
      };
      this.handlerCloseDetail();
      this.modal.update = true;
    },
    onClickShowDetail(schedule) {
      this.modal.info = true;
      this.schedule_detail = schedule;
    },
    handlerCloseDetail() {
      this.modal.info = false;
      this.schedule_detail = {};
    },
  },
};
</script>
<style lang="css">
.in-next-month {
  display: none !important;
}
.vc-container {
  font-family: 'JTMarnie';
}
</style>
<style lang="scss" scoped>
.flex-col {
  flex-direction: column;
}
.flex {
  display: flex;
}
.overflow-x-auto {
  overflow-x: auto;
}
.overflow-y-auto {
  overflow-y: auto;
}
.h-full {
  height: 100%;
}
::-webkit-scrollbar {
  width: 0px;
}
::-webkit-scrollbar-track {
  display: none;
}
/deep/ .custom-calendar.vc-container {
  --day-border: 1px solid #b8c2cc;
  --day-border-highlight: 1px solid #b8c2cc;
  --day-width: 90px;
  --day-height: 125px;
  --weekday-bg: #f8fafc;
  --weekday-border: 1px solid #eaeaea;
  border-radius: 0;
  width: 100%;
  & .vc-header {
    background-color: #f1f5f8;
    padding: 10px 0;
  }
  & .vc-weeks {
    padding: 0;
  }
  & .vc-weekday {
    background-color: var(--weekday-bg);
    border-bottom: var(--weekday-border);
    border-top: var(--weekday-border);
    padding: 5px 0;
  }
  & .vc-day {
    padding: 0 5px 3px 5px;
    text-align: left;
    height: var(--day-height);
    min-width: var(--day-width);
    background-color: white;
    &.weekday-1,
    &.weekday-7 {
      background-color: #eff8ff;
    }
    &:not(.on-bottom) {
      border-bottom: var(--day-border);
      &.weekday-1 {
        border-bottom: var(--day-border-highlight);
      }
    }
    &:not(.on-right) {
      border-right: var(--day-border);
    }
  }
  & .vc-day-dots {
    margin-bottom: 5px;
  }
}
</style>
