<template>
  <b-modal size="md" :no-close-on-esc="!canCloseModal" :no-close-on-backdrop="!canCloseModal" v-model="computedShowModal" :title="$t('payments.modal_withdraw.title')" hide-footer @hidden="closeModalAdd">
    <el-steps :active="current_step" finish-status="success" simple="">
      <el-step title="Fill Data"></el-step>
      <el-step title="Confirm"></el-step>
      <el-step title="Done"></el-step>
    </el-steps>
    <template v-if="current_step == 1">
      <p>{{ $t('payments.modal_withdraw.sub_title') }}</p><br/>
      <b-form ref="formAddAccount" @submit.prevent="stepHandler">
        <b-form-group :label="$t('payments.form_withdraw.amount')">
          <el-input
            v-model="v$.form.amount.$model"
            size="small"
            :placeholder="$t('payments.placeholder_withdraw.amount')"
          >
            <template slot="prepend">Rp.</template>
          </el-input>
          <b-form-invalid-feedback :state="!v$.form.amount.$invalid" v-if="v$.form.amount.$errors[0]">
            <p v-for="(item, index) in v$.form.amount.$errors" :key="index">
              {{ item.$message }}
            </p>
            <!-- {{ $t('validation.' + v$.form.amount.$errors[0].$validator) }} -->
          </b-form-invalid-feedback>
        </b-form-group>
        <div class="d-flex flex-row-reverse">
          <el-button size="small" @click="stepHandler" :loading="loading.do_withdraw" class="btn btn-sm ml-2 btn-primary">{{ $t('general.next') }}</el-button>
          <el-button size="small" @click="closeModalAdd" :disabled="!canCloseModal" type="secondary">{{ $t('general.cancel') }}</el-button>
        </div>
      </b-form>
    </template>
    <template v-if="current_step == 2">
      <el-descriptions class="mt-2" title="" :column="1" border>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-wallet"></i>
            Withdraw Amount
          </template>
          Rp. {{ parseInt(form.amount).toLocaleString('id-ID') }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-money"></i>
            Transfer Fee
          </template>
          Rp. {{ transfer_fee.toLocaleString('id-ID') }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-coin"></i>
            Total Deducted
          </template>
          Rp. {{ (parseInt(form.amount) + transfer_fee).toLocaleString('id-ID') }}
        </el-descriptions-item>
      </el-descriptions>
      <div class="d-flex flex-row-reverse mt-2">
        <el-button size="small" @click="stepHandler" :loading="loading.do_withdraw" class="btn btn-sm ml-2 btn-primary">{{ $t('general.submit') }}</el-button>
        <el-button size="small" @click="closeModalAdd" :disabled="!canCloseModal" type="secondary">{{ $t('general.cancel') }}</el-button>
      </div>
    </template>
    <template v-if="current_step == 3">
      <el-descriptions class="mt-2" title="Withdraw success" :column="1" border>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-wallet"></i>
            Withdraw Amount
          </template>
          Rp. {{ parseInt(form.amount).toLocaleString('id-ID') }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-money"></i>
            Transfer Fee
          </template>
          Rp. {{ transfer_fee.toLocaleString('id-ID') }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-coin"></i>
            Total Deducted
          </template>
          Rp. {{ (parseInt(form.amount) + transfer_fee).toLocaleString('id-ID') }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <font-awesome icon="user"/>
            Account Name
          </template>
          {{ censored(withdraw_response.beneficiary_account_name) }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <font-awesome icon="credit-card"/>
            Account Number
          </template>
          {{ censored(withdraw_response.beneficiary_account_number) }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-coin"></i>
            Reference Number
          </template>
          {{ withdraw_response.reference_number }}
        </el-descriptions-item>
      </el-descriptions>
      <div class="d-flex flex-row-reverse mt-2">
        <el-button size="small" @click="closeModalAdd" :disabled="!canCloseModal" type="secondary">{{ $t('general.close') }}</el-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
// import { isEmpty, uniqBy } from 'lodash';
import useVuelidate from '@vuelidate/core';
import {
  helpers,
  maxValue,
  minValue,
  numeric,
  required,
} from '@vuelidate/validators';
import transactionApi from '../../../api/transactions';
import popupErrorMessages from '../../../library/popup-error-messages';

export default {
  name: 'CreateSettlementAccount',
  setup() {
    return { v$: useVuelidate() };
  },
  props: {
    showModalWithdraw: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      current_step: 1,
      transfer_fee: 5000,
      form: {
        amount: null,
      },
      loading: {
        do_withdraw: false,
      },
      show_modal: this.showModalWithdraw,
      withdraw_response: {},
    };
  },
  computed: {
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
    canCloseModal() {
      return !this.loading.do_withdraw;
    },
    companyProfile() {
      return this.$store.state.auth.companyProfile || {};
    },
    computedShowModal: {
      get() {
        return this.show_modal;
      },
      set(val) {
        this.show_modal = val;
      },
    },
  },
  validations() {
    return {
      form: {
        amount: {
          numeric,
          required,
          minValue: minValue(10000),
          maxValue: maxValue(250000000),
          safeInteger: helpers.withMessage('Amount should not a decimal', (value) => Number.isSafeInteger(parseInt(value))),
        },
      },
    };
  },
  methods: {
    censored(str) {
      const end = str.slice(-4);
      const reserve_percentage = 0.3;
      return str.slice(0, parseInt(str.length * reserve_percentage)) + end.padStart(12, '*');
    },
    submitCreateAccount() {
      this.v$.form.$touch();
      if (this.v$.form.$error) return;
      this.$emit('submitCreateAccount', this.form);
    },
    closeModalAdd() {
      this.reset();
      this.$emit('closeModalAdd');
    },
    reset() {
      this.current_step = 1;
      this.form = {
        amount: null,
      };
      this.withdraw_response = {};
    },
    handleSendFile(file, target) {
      this.$emit('handleSendFile', file, target);
    },
    async stepHandler() {
      if (this.current_step === 1) {
        this.v$.form.$touch();
        if (this.v$.form.$error) return;
      }
      if (this.current_step === 2) {
        await this.doSubmitWithdraw();
      }
      this.current_step += 1;
    },
    async doSubmitWithdraw() {
      this.v$.form.$touch();
      if (this.v$.form.$error) return;
      this.loading.do_withdraw = true;
      this.form.amount = parseInt(this.form.amount);
      const response = await transactionApi.payoutBalance({
        workspace_id: this.activeWorkspace._id,
        data: this.form,
      });
      this.loading.do_withdraw = false;
      if (response.error) {
        this.current_step -= 1;
        popupErrorMessages(response);
      } else {
        this.withdraw_response = response.data;
        this.$message({
          type: 'success',
          message: 'Withdraw success',
        });
        this.$parent.accountList();
      }
    },
  },
  watch: {
    showModalWithdraw() {
      if (this.showModalWithdraw) this.v$.form.$touch();
      this.show_modal = this.showModalWithdraw;
    },
  },
};
</script>
