import QRCode from 'qrcode';
import workspacesAPI from '../../api/workspaces';
import ls from '../../library/localStorage';

export default {
  namespaced: true,
  state: {
    workspaces: ls.get('workspaces') || [],
    activeWorkspace: ls.get('activeWs') || {},
    workspaceBalance: ls.get('workspaceBalance') || 0,
    workspaceFreeQuota: ls.get('workspaceFreeQuota') || {},
    workspaceChannels: ls.get('workspaceChannels') || [],
  },
  getters: {
    workspacesLoaded(state) {
      return !!state.workspaces;
    },
    hasWorkspaces(state) {
      return !!(state.workspaces && state.workspaces.length);
    },
  },
  mutations: {
    clearActiveWorkspace(state) {
      state.activeWorkspace = {};
      state.workspaceChannels = [];
    },
    setActiveWorkspace(state, workspace) {
      state.activeWorkspace = workspace;
    },
    setWorkspaces(state, workspaces) {
      state.workspaces = workspaces;
    },
    clearWorkspaces(state) {
      state.workspaces = [];
    },
    setWorkspaceBalance(state, data) {
      state.workspaceBalance = data.balance;
      state.workspaceFreeQuota = {
        whatsapp: data.free_whatsapp,
        whatsapp_bi: data.free_business_initiated,
        whatsapp_ui: data.free_user_initiated,
        whatsapp_mkt: data.free_whatsapp_marketing,
        whatsapp_auth: data.free_whatsapp_authentication,
        whatsapp_svc: data.free_whatsapp_service,
        whatsapp_util: data.free_whatsapp_utility,
        free_credit: data.free_credit,
      };
    },
    setWorkspaceChannels(state, channels) {
      state.workspaceChannels = channels;
    },
  },
  actions: {
    clearWorkspaces({ commit }) {
      commit('clearWorkspaces');
      ls.remove('workspaces');
    },
    setActiveWorkspace({ commit }, workspace) {
      commit('setActiveWorkspace', workspace);
      ls.set('activeWs', workspace);
    },
    clearActiveWorkspace({ commit }) {
      commit('clearActiveWorkspace');
      ls.remove('activeWs');
      ls.remove('workspaceChannels');
    },
    loadWorkspaces({ commit }, query) {
      return new Promise((resolve = () => {}, reject = () => {}) => {
        workspacesAPI.getList(query)
          .then((res) => {
            if (!res.error) {
              res.data.rows = res.data.rows.map((v, index) => {
                v.stats = {
                  open_ticket: 0,
                  total_ticket: 0,
                  total_queue: 0,
                };
                if (v.configuration && v.configuration.trial_keyword && v.deep_link_url) {
                  QRCode.toDataURL(v.deep_link_url).then((result) => {
                    res.data.rows[index].qrcode = result;
                  });
                }
                v.stats_loading = true;
                return v;
              });
              commit('setWorkspaces', res.data.rows);
              ls.set('workspaces', res.data.rows);
              resolve(res);
            }
            if (res.error.title === 'need_approve_new_wappin') {
              reject('need_approve_new_wappin');
            }
          }).catch((err) => {
            reject(err);
          });
      });
    },
    setWorkspaceBalance({ commit }, balance) {
      commit('setWorkspaceBalance', balance);
      ls.set('workspaceBalance', balance);
    },
    setWorkspaceChannels({ commit }, channels) {
      commit('setWorkspaceChannels', channels);
      ls.set('workspaceChannels', channels);
    },
  },
};
