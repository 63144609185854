<template>
  <div class="page-content">
    <div class="card row">
      <div class="card-body col-md-12">
        <el-alert :closable="false" class="mb-3" title="💡 Info" type="success">
          <p><b>Leads custom settings</b> are used to customize and organize the steps in a lead management process. They allow you to define the specific steps a lead goes through, manage the flow of leads, and make adjustments such as reordering or transferring leads between steps.
            Custom settings also help to ensure leads are properly assigned and tracked throughout their journey, enabling more efficient sales or marketing management.
            Here are a few things to keep in mind when creating custom leads :
            <ul>
              <li>The step must be filled in with at least 2 and no more than 5.</li>
              <li>To change the order of the steps, tap the three dots icon on the left and drag to reorder.</li>
              <li>If you delete a step that contains leads, you will need to move those leads to another step.</li>
            </ul>
          </p>
        </el-alert>
        <hr>
        <div class="row m-2" v-if="isCanChange()">
          <button class="btn btn-outline-primary mt-3" @click="addStep" v-if="steps_list.length < 5">
            <font-awesome icon="plus"/> Add Leads Step
          </button>
        </div>
        <div v-for="(v,i) in listSteps()" :key="i">
          <div class="row" @drop="onDrop($event, v.order)" @dragover.prevent @dragenter.prevent>
            <div class="card card-list col-md-8 mx-3 my-2" draggable @dragstart="startDrag($event, v)">
              <div class="card-list-body">
                <div class="row align-items-center text-center" style="justify-content: space-between">
                  <div class="d-flex align-items-center col-md-11">
                    <div class="d-flex cursor-pointer">
                      <FeatherIcon style="margin-left: -5px" type="more-vertical" class="link-icon"/>
                      <FeatherIcon style="margin-left: -15px" type="more-vertical" class="link-icon"/>
                    </div>
                    <el-input v-if="isCanChange()" placeholder="Input step name for leads" class="ml-2 full-width" type="text" show-word-limit maxlength="30" v-model="v.label"/>
                    <p v-else>{{ v.label }}</p>
                  </div>
                  <div class="col-md-1" v-if="isCanChange()">
                    <button class="btn btn-outline-danger btn-sm" @click="removeStep(v)">
                      <font-awesome icon="trash"/>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="this.steps_list.length > 0">
          <div class="col-md-8 mt-2 ml-4" v-if="isCanChange()">
            <button class="btn btn-primary float-right" :disabled="listSteps().length < 2" @click="updateSteps" :loading="loading.update_step">
              Update
            </button>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      :title="`Move leads from ${delete_step} step before delete step`"
      :visible.sync="modal.delete"
      width="50%">
      <el-form label-width="auto" @reset="closeModal" class="full-width mb-5">
        <el-form-item :label="`${item.name} to `" v-for="item in leads_data" :key="item.id">
          <el-select v-model="form[item.id]" placeholder="Choose step to move" style="width: 100%">
            <el-option
              v-for="step in choose_steps"
              :key="step.key"
              :label="step.label"
              :value="step.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="float-right">
          <el-button size="small" type="primary" @click="moveLeads">Submit & Delete Step</el-button>
          <el-button size="small" @click="closeModal">Cancel</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import popupErrorMessages from '@/library/popup-error-messages';
import leadsAPI from '../../../api/leads';

export default {
  name: 'LeadsSettings',
  metaInfo() {
    return {
      title: 'Leads Setting',
    };
  },
  data() {
    return {
      modal: {
        delete: false,
      },
      loading: {
        update_step: false,
        delete_step: false,
      },
      form: {},
      form_a: '',
      loaderStack: 0,
      loader: null,
      steps_list: [],
      choose_steps: [],
      leads_data: [],
      delete_step: '',
      delete_step_key: '',
    };
  },
  computed: {
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
  },
  mounted() {
    this.loadData();
    this.isCanChange();
  },
  methods: {
    isCanChange() {
      let result = false;
      const { is_pic } = this.$store.state.auth.userProfile;
      if (is_pic === true) {
        result = true;
      }
      return result;
    },
    upperCaseFirst(string) {
      return _.upperFirst(string);
    },
    addStep() {
      const len = this.steps_list.length;
      this.steps_list.push({
        label: '',
        order: len,
      });
      console.log(this.steps_list);
    },
    listSteps() {
      let step_arr = [];
      if (this.steps_list.length > 0) {
        step_arr = [...this.steps_list].sort((a, b) => a.order - b.order);
      }
      return step_arr;
    },
    async loadData() {
      this.showLoader();
      const response = await leadsAPI.get_steps({ workspace_id: this.activeWorkspace._id });
      this.hideLoader();
      await popupErrorMessages(response);
      if (response.data.steps) {
        this.steps_list = response.data.steps;
      } else {
        this.steps_list = [];
      }
    },
    updateSteps() {
      this.showLoader();
      leadsAPI.update_steps({
        workspace_id: this.activeWorkspace._id,
        data: {
          custom_steps: this.steps_list,
        },
      }).then(async (res) => {
        this.hideLoader();
        await popupErrorMessages(res);
        this.steps_list = res.data;
        this.$message({
          message: this.$t('leads.success.step.update'),
          type: 'success',
        });
      }).catch(async () => {
        this.hideLoader();
      });
    },
    async removeStep(data) {
      const steps_index = this.steps_list.findIndex((v) => v.key === data.key);
      if (!data.key) {
        this.steps_list.splice(steps_index, 1);
        let i = 0;
        this.steps_list.sort((a, b) => a.order - b.order);
        this.steps_list.map((v) => {
          v.order = i;
          i += 1;
          return v;
        });
      } else {
        this.form = {};
        const res = await leadsAPI.get_leads_all({
          workspace_id: this.activeWorkspace._id,
          step_key: data.key,
        });
        await popupErrorMessages(res);
        if (res.data.length > 0) {
          this.leads_data = res.data;
          res.data.forEach((v) => {
            this.$set(this.form, v.id, '');
            this.form[v.id] = '';
          });
          this.choose_steps = _.cloneDeep(this.steps_list);
          this.choose_steps.splice(steps_index, 1);
          this.delete_step = data.label;
          this.delete_step_key = data.key;
          this.modal.delete = true;
        } else {
          this.$confirm(`Are you sure want to delete ${data.label} step?`, 'Delete Step', {
            confirmButtonText: this.$t('general.yes'),
            cancelButtonText: this.$t('general.no'),
            type: 'warning',
            center: true,
            /* eslint-disable no-param-reassign */
            beforeClose: async (action, instance, cb) => {
              if (action === 'confirm') {
                instance.confirmButtonLoading = true;
                leadsAPI.delete_step({
                  workspace_id: this.activeWorkspace._id,
                  key: data.key,
                }).then(async (response) => {
                  this.hideLoader();
                  await popupErrorMessages(response);
                  this.closeModal();
                  this.$message({
                    message: this.$t('leads.success.step.delete'),
                    type: 'success',
                  });
                  this.loadData();
                }).catch(async () => {
                  this.hideLoader();
                });
              }
              instance.confirmButtonLoading = false;
              cb();
            },
          }).catch(() => {});
        }
      }
    },
    moveLeads() {
      if (this.delete_step_key !== '') {
        this.showLoader();
        leadsAPI.delete_step({
          workspace_id: this.activeWorkspace._id,
          key: this.delete_step_key,
          form: this.form,
        }).then(async (res) => {
          this.hideLoader();
          await popupErrorMessages(res);
          this.form = {};
          this.modal.delete = false;
          this.$message({
            message: this.$t('leads.success.step.delete'),
            type: 'success',
          });
          this.loadData();
        }).catch(async () => {
          this.hideLoader();
        });
      } else {
        this.modal.delete = false;
        this.$message({
          message: this.$t('leads.failed.step.delete'),
          type: 'error',
        });
      }
    },
    closeModal() {
      this.leads_data = [];
      this.modal.delete = false;
    },
    showLoader() {
      if (this.loaderStack === 0) {
        this.loader = this.$loading.show();
      }
      this.loaderStack += 1;
    },
    hideLoader() {
      this.loaderStack -= 1;
      if (this.loaderStack === 0) {
        this.loader.hide();
      }
    },
    startDrag(evt, item) {
      evt.dataTransfer.dropEffect = 'move';
      evt.dataTransfer.effectAllowed = 'move';
      evt.dataTransfer.setData('order_from', item.order);
    },
    onDrop(evt, order_to) {
      const order_from = parseInt(evt.dataTransfer.getData('order_from'), 0);
      const move_from = this.steps_list.findIndex((v) => v.order === order_from);
      const move_to = this.steps_list.findIndex((v) => v.order === order_to);
      this.steps_list[move_from].order = order_to;
      this.steps_list[move_to].order = order_from;
    },
  },
};
</script>

<style lang="scss">
  .drop-zone {
    background-color: #eee;
    margin-bottom: 10px;
    padding: 10px;
  }
</style>
