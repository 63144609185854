import authAPI from '../../api/auth';
import ls from '../../library/localStorage';

export default {
  namespaced: true,
  state: {
    token: ls.get('token') || null,
    userProfile: ls.get('userProfile') || {},
    companyProfile: ls.get('companyProfile') || {},
    // extension: '4008',
    sip: ls.get('sipAccess') || {},
  },
  getters: {
    isLogin: (state) => !!state.token,
    token: (state) => state.token,
  },
  mutations: {
    setLogin(state, [token, userProfile]) {
      state.token = token;
      state.userProfile = userProfile;
    },
    setAccess(state, data) {
      state.sip = data;
    },
    setLogout(state) {
      state.token = null;
      state.extension = '';
      state.userProfile = null;
      state.sip = null;
    },
    setCompanyProfile(state, data) {
      state.companyProfile = data;
    },
  },
  actions: {
    doLogin({ commit }, { email, password }) {
      return new Promise((resolve = () => {}, reject = () => {}) => {
        authAPI
          .login(email, password)
          .then((res) => {
            if (res.data.token && res.data.users) {
              commit('setLogin', [res.data.token, res.data.users]);
              commit('setAccess', res.data.call_access);
              ls.set('token', res.data.token);
              ls.set('sipAccess', res.data.call_access);
              ls.set('userProfile', res.data.users);
              ls.set('companyProfile', res.data.users.company);
            }
            resolve(res);
          }).catch((err) => {
            reject(err);
          });
      });
    },
    doVerifyTokenLogin({ commit }, { token, otp_code }) {
      return new Promise((resolve = () => {}, reject = () => {}) => {
        authAPI
          .verify_login_otp({ token, otp_code })
          .then((res) => {
            if (res.data.token && res.data.users) {
              commit('setLogin', [res.data.token, res.data.users]);
              ls.set('token', res.data.token);
              ls.set('userProfile', res.data.users);
              ls.set('companyProfile', res.data.users.company);
            }
            resolve(res);
          }).catch((err) => {
            reject(err);
          });
      });
    },
    doLogout({ commit, dispatch }) {
      return new Promise((resolve = () => {}) => {
        dispatch('workspace/clearWorkspaces', null, { root: true });
        dispatch('workspace/clearActiveWorkspace', null, { root: true });
        ls.remove('token');
        ls.remove('sipAccess');
        ls.remove('userProfile');
        ls.remove('companyProfile');
        ls.remove('activeWs');
        ls.clear();
        commit('setLogout');
        resolve();
      });
    },
    updateCompanyProfile({ commit }, data) {
      commit('setCompanyProfile', data);
    },
    doLoginCobrand({ commit }, { token }) {
      return new Promise((resolve = () => {}, reject = () => {}) => {
        authAPI
          .login_cobrand(token)
          .then((res) => {
            if (res.data.token && res.data.users) {
              commit('setLogin', [res.data.token, res.data.users]);
              ls.set('token', res.data.token);
              ls.set('userProfile', res.data.users);
              ls.set('companyProfile', res.data.users.company);
            }
            resolve(res);
          }).catch((err) => {
            reject(err);
          });
      });
    },
  },
};
