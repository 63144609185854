import api from '../library/axios';
import RSA from '../library/rsa-encrypt';

function login(email, password) {
  return api({
    method: 'post',
    url: '/api/auth/login',
    data: {
      email,
      password: RSA.encrypt(password, 'base64'),
    },
  });
}

function register({
  captcha_token,
  company_name,
  company_brand,
  company_address,
  name,
  phone_number,
  email,
  password,
  app_source,
}) {
  return api({
    method: 'post',
    url: '/api/auth/register',
    data: {
      captcha_token,
      company_name,
      company_brand,
      company_address,
      name,
      phone_number,
      email,
      password: RSA.encrypt(password, 'base64'),
      app_source,
    },
  });
}

function activate(token) {
  return api({
    method: 'get',
    url: `/api/auth/activation/${token}`,
  });
}

function logout() {
  return api({
    method: 'post',
    url: '/api/auth/logout',
  });
}

function change_password({ old_password, new_password, confirm_password }) {
  return api({
    method: 'patch',
    url: '/api/auth',
    data: {
      old_password: RSA.encrypt(old_password, 'base64'),
      new_password: RSA.encrypt(new_password, 'base64'),
      confirm_password: RSA.encrypt(confirm_password, 'base64'),
    },
  });
}

function init_reset_password(data) {
  return api({
    method: 'post',
    url: '/api/auth/reset-password',
    data,
  });
}

function verify_reset_password({ token }) {
  return api({
    url: `/api/auth/reset-password/${token}`,
  });
}

function reset_password({ token, new_password, confirm_password }) {
  return api({
    method: 'post',
    url: `/api/auth/reset-password/${token}`,
    data: {
      new_password: RSA.encrypt(new_password, 'base64'),
      confirm_password: RSA.encrypt(confirm_password, 'base64'),
    },
  });
}

function login_cobrand(token) {
  return api({
    method: 'post',
    url: '/api/auth/cobrand-login',
    data: {
      redirect_token: token,
    },
  });
}

function accept_terms_condition_migration() {
  return api({
    method: 'post',
    url: '/api/legal/accept-terms-condition-migration',
  });
}

function unregister() {
  return api({
    method: 'POST',
    url: '/api/auth/unregister',
  });
}

function init_2fa() {
  return api({
    url: '/api/2fa-verification/init',
  });
}

function verify_enabled_2fa(data) {
  return api({
    method: 'post',
    url: '/api/2fa-verification/verify',
    data,
  });
}

function verify_login_otp(data) {
  return api({
    method: 'post',
    url: '/api/auth/login/verify-2fa',
    data,
  });
}

export default {
  login,
  register,
  activate,
  logout,
  change_password,
  init_reset_password,
  verify_reset_password,
  reset_password,
  login_cobrand,
  accept_terms_condition_migration,
  unregister,
  init_2fa,
  verify_enabled_2fa,
  verify_login_otp,
};
